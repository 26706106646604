import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux actions
import { closeDeclineReasonModal } from '../../../../actions/siteadmin/modalActions';
// helpers
import messages from '../../../../locale/messages';
// components
import DeclineReason from '../DeclineReason/DeclineReason';

class DeclineReasonModal extends React.Component {
    static defaultProps = {
        isOrderDetailPage: false
    };

    render() {
        const { closeDeclineReasonModal, bookingId, currentPage, refetch, isDeclineModalOpen, isOrderDetailPage } = this.props;

        return (
            <div className={'hidden-print'}>
                <Modal show={isDeclineModalOpen} onHide={closeDeclineReasonModal} className={'listItemModal'}>
                    <Modal.Header closeButton>
                        <FormattedMessage {...messages.orderDeclineReason} />
                    </Modal.Header>
                    <Modal.Body>
                        <DeclineReason bookingId={bookingId} currentPage={currentPage} refetch={refetch} isOrderDetailPage={isOrderDetailPage} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

const mapState = (state) => ({
    isDeclineModalOpen: state.modalStatus.isDeclineModalOpen,
    bookingId: state.modalStatus.bookingId,
    currentPage: state.modalStatus.currentPage
});

const mapDispatch = {
    closeDeclineReasonModal
}

export default injectIntl(connect(mapState, mapDispatch)(DeclineReasonModal));