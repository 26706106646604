import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash'
import { reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Row,
  Col,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Loader from '../../../Common/Loader';
import getPaymentMethodsQuery from './getPaymentMethods.graphql';
import messages from '../../../../locale/messages';
import s from '../Payout.css';
import rs from '../../../storeCommon.css';
class PayoutMethods extends Component {

  static propTypes = {
    loading: PropTypes.string.isRequired,
    getPaymentMethods: PropTypes.shape({
      results: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        processedIn: PropTypes.string,
        fees: PropTypes.string,
        currency: PropTypes.string,
        details: PropTypes.string,
        isEnable: PropTypes.bool,
        paymentType: PropTypes.number,
        status: PropTypes.number
      }).isRequired)
    })
  };

  static defaultProps = {
    PaymentMethodsData: {
      loading: true,
      getPaymentMethods: {
        results: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { PaymentMethodsData: { loading, getPaymentMethods } } = this.props;
    const { change, paymentMethodId } = this.props;
    if (!loading && getPaymentMethods?.results?.length > 0 && !paymentMethodId) {
      change('methodId', getPaymentMethods?.results[0]?.id);
      change('paymentType', getPaymentMethods?.results[0]?.paymentType);
      change('currency', getPaymentMethods?.results[0]?.currency);
      change('isEnable', getPaymentMethods?.results[0]?.isEnable);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { PaymentMethodsData: { loading, getPaymentMethods } } = nextProps;
    const { change, paymentMethodId } = nextProps;
    if (!loading && getPaymentMethods?.results?.length > 0 && !paymentMethodId) {
      change('methodId', getPaymentMethods?.results[0]?.id);
      change('paymentType', getPaymentMethods?.results[0]?.paymentType);
      change('currency', getPaymentMethods?.results[0]?.currency);
      change('isEnable', getPaymentMethods?.results[0]?.isEnable);
    }
  }

  handleChange(methodId, paymentType, currency, isEnable) {
    const { change } = this.props;
    change('methodId', methodId);
    change('paymentType', paymentType);
    change('currency', currency);
    change('isEnable', isEnable);
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const { PaymentMethodsData: { loading, getPaymentMethods } } = this.props;
    const { paymentMethodId } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={'mainContainer'}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                  <FormattedMessage {...messages.addPayout} />
                </h1>
                <Form className={s.fullWidth} onSubmit={handleSubmit}>
                  <div className={s.panelBody}>
                    <p className={s.payoutIntro}>
                      <FormattedMessage {...messages.payoutIntro1} />
                    </p>
                    {
                      loading && <div><Loader containerClass={'text-center'} /></div>
                    }
                    {
                      !loading && getPaymentMethods?.results != undefined && getPaymentMethods?.results?.length > 0 && <div className={cx(s.noPadding, 'storeTableContainer')}><div className={cx(s.tableCss, 'tableCss', 'storetableSticky', 'storeTableCss', 'NewResponsiveTable')}>
                        <table className={cx('table', s.noBorder)}>
                          <thead>
                            <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                              <th className={s.noBorder} scope="col" />
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle1} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle2} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle3} /></th>
                              <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle4} /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getPaymentMethods?.results?.map((item, index) => {
                                let checked = false;
                                if (item.id === paymentMethodId) {
                                  checked = true;
                                }
                                return (
                                  <tr className={cx(s.sectionTitleLight)} key={index}>
                                    <td>
                                      <input name="methodId" type="radio" checked={checked} value={item.id} onChange={() => this.handleChange(item.id, item.paymentType, item.currency, item.isEnable)} />
                                    </td>
                                    <td data-label={formatMessage(messages.payoutTitle)}><label className={s.radioText}>{item.name}</label></td>
                                    <td data-label={formatMessage(messages.payoutTitle1)}>{item.processedIn}</td>
                                    <td data-label={formatMessage(messages.payoutTitle2)}>{item.fees}</td>
                                    <td data-label={formatMessage(messages.payoutTitle3)}>{item.currency}</td>
                                    <td data-label={formatMessage(messages.payoutTitle4)}>{item.details}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div> </div>
                    }
                    {
                      !loading && getPaymentMethods?.results != undefined && getPaymentMethods?.results?.length === 0 && <div> <FormattedMessage {...messages.noPaymentFound} /> </div>
                    }

                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12} className={cx('btnGroupContainer', 'btnGroupContainerMobileDir', 'btnGroupContainerSpace')}>
                        <Button onClick={previousPage} className={cx(rs.btnPrimaryBorder, s.btnWidthMobile)}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        <Button type="submit" className={cx(rs.btnPrimary, s.btnWidthMobile)}>
                          <FormattedMessage {...messages.next} />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

PayoutMethods = reduxForm({
  form: 'PayoutForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PayoutMethods);

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
  paymentMethodId: selector(state, 'methodId')
});

const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s, rs),
  connect(mapState, mapDispatch),
  graphql(getPaymentMethodsQuery, {
    name: 'PaymentMethodsData',
    options: {
      ssr: false,
    }
  }),
)(PayoutMethods);