require('dotenv').config();

module.exports = {

  port: process.env.PORT || 3000,// Node.js app
  trustProxy: process.env.TRUST_PROXY || 'loopback',  // https://expressjs.com/en/guide/behind-proxies.html
  siteUrl: process.env.SITE_URL || 'https://www.culebraeats.com', // SITE URL
  socketUrl: process.env.SOCKET_URL || 'https://www.culebraeats.com',  //Socket URL

  api: { // API Gateway
    clientUrl: process.env.API_CLIENT_URL || '', // API URL to be used in the client-side code
    serverUrl: process.env.API_SERVER_URL || `http://localhost:${process.env.PORT || 3000}`, // API URL to be used in the server-side code
    apiEndpoint: process.env.API_ENDPOINT_URL || 'https://www.culebraeats.com/api'
  },

  databaseUrl: process.env.DATABASE_URL, // Database

  locales: [ // default locale is the first one
    'en-US', /* @intl-code-template '${lang}-${COUNTRY}', */
    'es',
    'fr-FR',
    'id-ID',
    'ja-JA',
    'ru-RU',
    'ar'  /* @intl-code-template-end */
  ],

  cronTimezone: process.env.CRON_TIMEZONE, // CRONs Timezone
  // Refer this link for valid timezone https://raw.githubusercontent.com/node-cron/tz-offset/master/generated/offsets.json

  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR || '/images/license/', // License Upload

  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR || '/images/vehicle/', // Vehicle Upload

  profilePhotouploadDir: process.env.PROFILE_PHOTO_UPLOAD_DIR || '/images/avatar/', // Profile photo upload

  categoryUploadDir: process.env.CATEGORY_PHOTO_UPLOAD_DIR || '/images/category/', // Category photo upload

  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR || '/images/logo/', // Logo photo upload

  ogImageUploadDir: process.env.OG_IMAGE_UPLOAD_DIR || '/images/og-image/', // OG image upload

  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR || '/images/homepage/', // Homepage photo upload

  staticpageUploadDir: process.env.STATICPAGE_UPLOAD_DIR || '/images/staticpage/', // Static page photo upload

  deliveryVehicleUploadDir: process.env.DELIVERY_VEHICLE_UPLOAD_DIR || '/images/delivery-vehicle/', // Delivery Vehicle Upload

  contentPageUploadDir: process.env.CONTENTPAGE_UPLOAD_DIR || '/images/contentpage/', // Content page photo upload 

  storeImageUploadDir: process.env.STORE_IMAGE_UPLOAD_DIR || '/images/store-image/', // Store image photo upload

  storeDocumentUploadDir: process.env.STORE_DOCUMENT_UPLOAD_DIR || '/images/store-document/', // Store image photo upload

  partnerHomepageUploadDir: process.env.PARTNER_HOMEPAGE_UPLOAD_DIR || '/images/partner-homepage/', // Partner homepage Image photo upload

  itemImageUploadDir: process.env.ITEM_IMAGE_UPLOAD_DIR || '/images/store-itemImage/', // Store item image

  promoCodeImageUploadDir: process.env.PROMO_CODE_IMAGE_UPLOAD_DIR || '/images/promo-code/', // Promo code image

  faviconUploadDir: process.env.FAVICON_UPLOAD_DIR || '/images/favicon/', // Favicon upload

  deliveryTypeImageUploadDir: process.env.DELIVERY_TYPE_IMAGE_UPLOAD_DIR || '/images/delivery-type/', // Delivery Type Image

  toneUploadDir: process.env.TONE_UPLOAD_DIR || '/images/tone/',  // Tone Upload

  googleMapAPI: 'AIzaSyBa8opHD4NxdrhGGMBqcUltrQU3qyLmez4', // Google map API key

  analytics: { // Web analytics https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID, // UA-XXXXX-X
  },

  payment: { // Payment - Stripe
    stripe: {
      secretKey: process.env.STRIPE_SECRET,
      publishableKey: 'pk_test_51QDHilGIlhMu5jT0xUkkevP0aBmUGMNSOeUKpFbi1vzAp9TOsbZQTgpkfFg75lUJM0Vv7dgO0W9XcOgXlRTX1I3b00NzYeWodR'
    }
  },

  auth: { // Authentication
    jwt: { secret: process.env.JWT_SECRET },

    facebook: { // https://developers.facebook.com/
      id: process.env.FACEBOOK_APP_ID,
      secret: process.env.FACEBOOK_APP_SECRET
    },

    google: { // https://cloud.google.com/console/project
      id: process.env.GOOGLE_CLIENT_ID,
      secret: process.env.GOOGLE_CLIENT_SECRET
    },

    twitter: { // https://apps.twitter.com/
      key: process.env.TWITTER_CONSUMER_KEY,
      secret: process.env.TWITTER_CONSUMER_SECRET
    }
  },
};
