import gql from "graphql-tag";

export const queryIntl = gql`
query intl($locale: String!) {
  intl(locale: $locale) {
    id
    message
  }
}`

export const itemAvailable = gql`query{
    itemAvailable {
        status
        errorMessage
    }
}`;

export const sendForgotPasswordLink = gql`
mutation sendForgotPasswordLink($email: String!) {
  sendForgotPasswordLink(email: $email) {
    status
    errorMessage
  }
}`

export const shopUserLogout = gql`query{
  shopUserLogout {
    status
  }
}`
export const shopUserLogin = gql`
query ($email: String!, $password: String!, $deviceId: String) {
  shopUserLogin(email: $email, password: $password, deviceId: $deviceId) {
    status
    errorMessage
    result {
      id
      email
    }
  }
}`
export const checkShopUser = gql`
query ($email: String!) {
  checkShopUser(email: $email) {
    status
    errorMessage
  }
}`
export const updateForgotPassword = gql`
mutation updateForgotPassword($email: String!, $token: String!, $newPassword: String!) {
  updateForgotPassword(email: $email, token: $token, newPassword: $newPassword) {
    status
    errorMessage
  }
}`

export const getCommonSettings = gql`query {
	getAllCommonSettingsData {
	  status
	  errorMessage
	  count
	  results {
		id
		typeName
		typeLabel
		fieldType
		isEnable
		step
	  }
	}
  }`

export const getCurrencyRates = gql`
query {
  getCurrencyRates {
    base
    rates
  }
}`

export const getCurrenciesData = gql`query {
	getCurrencies {
	  id
	  symbol
	  isEnable
	  isPayment
	  isBaseCurrency
	  status
	}
  }
`
export const getSiteSettings = gql`
query getSiteSettings($type:String){
	getSiteSettings(type:$type){
		results{
			name
			value
		}
	}
}`;
export const updateToneMutation = gql`
mutation updateTone {
	updateTone {
		status
	}
}`

export const siteSettingsMuation = gql`
mutation updateSiteSettings(
	$siteName: String
	$siteTitle: String
	$metaDescription: String
	$facebookLink: String
	$twitterLink: String
	$instagramLink: String
	$metaKeyword: String
	$homeLogo: String
	$youtubeLink: String
	$currency: String
	$modifiers: String
	$favicon: String
	$contactNumber: String
	$contactEmail: String
	$contactSkype: String
	$notificationInterval: String
	$ogImage: String
) {
	updateSiteSettings(
		siteName: $siteName
		siteTitle: $siteTitle
		metaDescription: $metaDescription
		facebookLink: $facebookLink
		twitterLink: $twitterLink
		instagramLink: $instagramLink
		metaKeyword: $metaKeyword
		homeLogo: $homeLogo
		youtubeLink: $youtubeLink
		currency: $currency
		modifiers: $modifiers
		favicon: $favicon
		contactNumber: $contactNumber
		contactEmail: $contactEmail
		contactSkype: $contactSkype
		notificationInterval: $notificationInterval
		ogImage : $ogImage
	) {
		status
		errorMessage
	}
}`
export const mobileSettings = gql`mutation updateMobileSettings(
	$distanceUnits: String
	$appForceUpdate: String
	$userAndroidVersion: String
	$userIosVersion: String
	$driverAndroidVersion: String
	$driverIosVersion: String
	$preferredDelivery: String
	$requestTimeTone: String
	$isRequestTimerToneEnable: String
	$openAppOnRequest: String
	$requestToneFile: String
	$orderAcceptInterval: String
) {
	updateMobileSettings(
		distanceUnits: $distanceUnits
		appForceUpdate: $appForceUpdate
		userAndroidVersion: $userAndroidVersion
		userIosVersion: $userIosVersion
		driverAndroidVersion: $driverAndroidVersion
		driverIosVersion: $driverIosVersion
		preferredDelivery: $preferredDelivery
		requestTimeTone: $requestTimeTone
		isRequestTimerToneEnable: $isRequestTimerToneEnable
		openAppOnRequest: $openAppOnRequest
		requestToneFile: $requestToneFile
		orderAcceptInterval: $orderAcceptInterval
	) {
		status
		errorMessage
	}
}
`
export const updateConfigSettings = gql`mutation updateConfigSettings(
	$smtpHost: String
	$smtpPort: String
	$smptEmail: String
	$smtpSender: String
	$smtpSenderEmail: String
	$smtpPassWord: String
	$twilioAccountSid: String
	$twilioAuthToken: String
	$twilioPhone: String
	$subtractTime: String
	$maxDistance: String
	$maxUploadSize: String
	$stripePublishableKey: String
	$fcmPushNotificationKey: String
	$paypalClientId: String
	$paypalSecret: String
	$paypalHost: String
	$fcmApiKey: String
	$fcmAuthDomain: String
	$fcmProjectId: String
	$fcmStorageBucket: String
	$fcmMessagingSenderId: String
	$fcmAppId: String
	$fcmMeasurementId: String
	$fcmVapidKey: String
	$enableFirebase: String
) {
	updateConfigSettings(
		smtpHost: $smtpHost
		smtpPort: $smtpPort
		smptEmail: $smptEmail
		smtpSender: $smtpSender
		smtpSenderEmail: $smtpSenderEmail
		smtpPassWord: $smtpPassWord
		twilioAccountSid: $twilioAccountSid
		twilioAuthToken: $twilioAuthToken
		twilioPhone: $twilioPhone
		maxUploadSize: $maxUploadSize
		stripePublishableKey: $stripePublishableKey
		subtractTime: $subtractTime
		maxDistance: $maxDistance
		fcmPushNotificationKey: $fcmPushNotificationKey
		paypalClientId: $paypalClientId
		paypalSecret: $paypalSecret
		paypalHost: $paypalHost
		fcmApiKey: $fcmApiKey
		fcmAuthDomain: $fcmAuthDomain
		fcmProjectId: $fcmProjectId
		fcmStorageBucket: $fcmStorageBucket
		fcmMessagingSenderId: $fcmMessagingSenderId
		fcmAppId: $fcmAppId
		fcmMeasurementId: $fcmMeasurementId
		fcmVapidKey: $fcmVapidKey
		enableFirebase: $enableFirebase
	) {
		status
		errorMessage
	}
}`

export const updatePartnerHomepageMuation = gql`mutation updatePartnerHomepage (
	$bannerTitle1: String,
	$bannerTitle2: String,
	$bannerContent1: String,
	$bannerImage1: String,
	$sliderTitle1: String,
	$sliderContent1: String,
	$sliderImage1: String,
	$sliderTitle2: String,
	$sliderContent2: String,
	$sliderImage2: String,
	$sliderTitle3: String,
	$sliderContent3: String,
	$sliderImage3: String,
	$sliderTitle4: String,
	$sliderContent4: String,
	$sliderTitle5: String,
	$infoTitle1: String,
	$infoImage1: String,
	$infoTitle2: String,
	$infoImage2: String,
	$infoTitle3: String,
	$infoImage3: String,
	$infoTitle4: String,
	$infoContent4: String,
	$buttonName: String,
	$buttonLink: String,
	$sliderImage5: String,
	$infoContent1: String,
	$infoContent2: String,
	$infoContent3: String,
	$bannerImage2: String,
) {
  updatePartnerHomepage (
	bannerTitle1: $bannerTitle1,
	bannerTitle2: $bannerTitle2,
	bannerContent1: $bannerContent1,
	bannerImage1: $bannerImage1,
	sliderTitle1: $sliderTitle1,
	sliderContent1: $sliderContent1,
	sliderImage1: $sliderImage1,
	sliderTitle2: $sliderTitle2,
	sliderContent2: $sliderContent2,
	sliderImage2: $sliderImage2,
	sliderTitle3: $sliderTitle3,
	sliderContent3: $sliderContent3,
	sliderImage3: $sliderImage3,
	sliderTitle4: $sliderTitle4,
	sliderContent4: $sliderContent4,
	sliderTitle5: $sliderTitle5,
	infoTitle1: $infoTitle1,
	infoImage1: $infoImage1,
	infoTitle2: $infoTitle2,
	infoImage2: $infoImage2,
	infoTitle3: $infoTitle3,
	infoImage3: $infoImage3,
	infoTitle4: $infoTitle4,
	infoContent4: $infoContent4,
	buttonName: $buttonName,
	buttonLink: $buttonLink,
	sliderImage5: $sliderImage5,
	infoContent1: $infoContent1,
	infoContent2: $infoContent2,
	infoContent3: $infoContent3,
	bannerImage2: $bannerImage2
  ) {
	status
  }
}`;

export const updatePromoCodeMutation = gql`mutation (
	$id: Int
	$title: String!
	$description: String!
	$code: String!
	$type: Int!
	$promoValue: Float!
	$currency: String
	$expiryDate: String
	$isEnable: String
	$promoCodeImage: String
	$isPrivate: Int
) {
	updatePromoCode(
		id: $id
		title: $title
		description: $description
		code: $code
		type: $type
		promoValue: $promoValue
		currency: $currency
		expiryDate: $expiryDate
		isEnable: $isEnable
		promoCodeImage: $promoCodeImage
		isPrivate: $isPrivate
	) {
		status
		errorMessage
	}
}`;

export const addPromoCodeMutation = gql`mutation(
  $title: String!
  $description: String!
  $code: String!
  $type: Int!
  $promoValue: Float!
  $currency: String
  $expiryDate: String
  $promoCodeImage: String
  $isPrivate: Int
) {
  addPromoCode(
    title: $title
    description: $description
    code: $code
    type: $type
    promoValue: $promoValue
    currency: $currency
    expiryDate: $expiryDate
    promoCodeImage: $promoCodeImage
    isPrivate: $isPrivate
  ) {
    status
    errorMessage
  }
}`

export const deletePromoCodeMutation = gql`mutation ($id: Int!) {
	deletePromoCode(id: $id) {
		status
		errorMessage
	}
}`
export const getAllPromoCode = gql`query($currentPage: Int) {
  getAllPromoCodes(currentPage: $currentPage) {
    status
    errorMessage
    count
    data {
      id
      title
      description
      code
      type
      promoValue
      currency
      expiryDate
      isEnable
      createdAt
      updatedAt
    }
  }
}
`
export const getConfigSettings = `query getConfigSettings($name: String) {
	getConfigSettings(name: $name) {
		title
		name
		encryptValue
	}
}`;

export const getStripeSettings = gql`
  {
    getStripeSettings {
      errorMessage
      status
      name
      value
    }
  }
`; 

