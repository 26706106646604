exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3NjWI {\n    display: inline-block;\n    margin-right: 12px;\n}\n._1mavW {\n    text-align: right;\n    margin-top: 28px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    -ms-flex-pack: end;\n        justify-content: flex-end;\n    grid-column-gap: 12px;\n    -webkit-column-gap: 12px;\n       -moz-column-gap: 12px;\n            column-gap: 12px;\n    grid-row-gap: 24px;\n    row-gap: 24px;\n}\n.KojO6 {\n    font-size: 18px;\n    color: #39483A;\n}\n._2wswr {\n    vertical-align: middle;\n    display: inline-block;\n}\n@media screen and (max-width: 1200px) {\n    ._1mavW {\n        text-align: center;\n    }\n    ._3NjWI {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    ._267vT {\n        padding: 12px 15px !important;\n    }\n}", ""]);

// exports
exports.locals = {
	"verifyBtn": "_3NjWI",
	"textVrify": "_1mavW",
	"labelColor": "KojO6",
	"vtrMiddle": "_2wswr",
	"btnPadding": "_267vT"
};