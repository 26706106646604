import messages from '../../../../locale/messages';
import { inputTextLimit, textAreaLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.infoTitle1) {
        errors.infoTitle1 = messages.required;
    } else if (values.infoTitle1.trim() == "") {
        errors.infoTitle1 = messages.required;
    } else if (values?.infoTitle1?.length > inputTextLimit) {
        errors.infoTitle1 = messages.textAreaError;
    }

    if (!values.infoTitle2) {
        errors.infoTitle2 = messages.required;
    } else if (values.infoTitle2.trim() == "") {
        errors.infoTitle2 = messages.required;
        errors.infoTitle2 = messages.required;
    } else if (values?.infoTitle2?.length > inputTextLimit) {
        errors.infoTitle2 = messages.textAreaError;
    }

    if (!values.infoTitle3) {
        errors.infoTitle3 = messages.required;
    } else if (values.infoTitle3.trim() == "") {
        errors.infoTitle3 = messages.required;
    } else if (values?.infoTitle3?.length > inputTextLimit) {
        errors.infoTitle3 = messages.textAreaError;
    }


    if (!values.infoContent1) {
        errors.infoContent1 = messages.required;
    } else if (values.infoContent1.trim() == "") {
        errors.infoContent1 = messages.required;
    } else if (values?.infoContent1?.length > textAreaLimit) {
        errors.infoContent1 = messages.textAreaError1;
    }

    if (!values.infoContent2) {
        errors.infoContent2 = messages.required;
    } else if (values.infoContent2.trim() == "") {
        errors.infoContent2 = messages.required;
    }  else if (values?.infoContent2?.length > textAreaLimit) {
        errors.infoContent1 = messages.textAreaError1;
    }

    if (!values.infoContent3) {
        errors.infoContent3 = messages.required;
    } else if (values.infoContent3.trim() == "") {
        errors.infoContent3 = messages.required;
    } else if (values?.infoContent3?.length > textAreaLimit) {
        errors.infoContent1 = messages.textAreaError1;
    }

    return errors;
};

export default validate;