exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".d-LbT {\n  font-size: 18px;\n  color: #767676;\n  margin-bottom: 30px;\n}\n\n._3s03m {\n  display: inline-block;\n  padding-left: 10px;\n  vertical-align: middle;\n}\n\n.pBE2q {\n  color: #FB5609 !important;\n  text-decoration: none !important;\n}\n\n._1HAvG {\n  color: #e83a30 !important;\n  text-decoration: none !important;\n}\n\n.T8zop {\n  color: red;\n}\n\n.Orh_A {\n  margin-left: 8px;\n  padding-right: 6px;\n}\n\n.xLVQw {\n  color: #FB5609 !important;\n  text-decoration: none !important;\n  display: inline-block;\n}\n\n._15bSn {\n  text-decoration: line-through;\n}\n\n.nYyRm {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  grid-gap: 15px;\n  gap: 15px;\n}\n\n@media screen and (max-width: 767px) {\n  ._3s03m {\n    display: block;\n    padding-left: 0;\n  }\n\n  ._3kM5z {\n    width: 100%;\n  }\n}\n\n.ycHeF {\n  width: 100%;\n  max-width: 24px;\n  vertical-align: middle;\n  margin-right: 6px;\n}", ""]);

// exports
exports.locals = {
	"infoBox": "d-LbT",
	"displayInlineBtn": "_3s03m",
	"veriyText": "pBE2q",
	"removeText": "_1HAvG",
	"errorMessage": "T8zop",
	"removeImg": "Orh_A",
	"linkTextColor": "xLVQw",
	"lineThrough": "_15bSn",
	"btnDisplayFlex": "nYyRm",
	"btnWidthMobile": "_3kM5z",
	"iconsWidth": "ycHeF"
};