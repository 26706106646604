import { inputTextLimit } from '../../../../helpers/inputTextLimit';
import messages from '../../../../locale/messages';

const validate = values => {

  const errors = {};

  if (!values.address1 || values.address1.toString().trim() == '') {
    errors.address1 = messages.required;
  } else if (values?.address1?.length > inputTextLimit) {
    errors.address1 = messages.textAreaError;
  }
  if (values?.address2?.length > inputTextLimit) {
    errors.address2 = messages.textAreaError;
  }

  if (!values.country) {
    errors.country = messages.required;
  }

  if (!values?.city || values?.city?.toString().trim() == '') {
    errors.city = messages.required;
  } else if (values?.city?.length > inputTextLimit) {
    errors.city = messages.textAreaError;
  }

  if (!values?.state || values?.state?.toString().trim() == '') {
    errors.state = messages.required;
  } else if (values?.state?.length > inputTextLimit) {
    errors.state = messages.textAreaError;
  }

  if (!values?.zipcode || values?.zipcode.toString().trim() == '') {
    errors.zipcode = messages.required;
  } else if (values?.zipcode?.length > inputTextLimit) {
    errors.zipcode = messages.textAreaError;
  }

  if (!values.payEmail) {
    errors.payEmail = messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.payEmail)) {
    errors.payEmail = messages.emailInvalid;
  }

  if (!values.currency) {
    errors.currency = messages.required;
  }

  return errors;
}

export default validate;