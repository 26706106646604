import messages from '../../../../locale/messages';
import { inputTextLimit, textAreaLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values?.shopName) {
        errors.shopName = messages.required;
    } else if (values?.shopName?.trim() == "") {
        errors.shopName = messages.required;
    } else if (!isNaN(values?.shopName)) {
        errors.shopName = messages.invalid
    } else if (values?.shopName?.length < 2) {
        errors.shopName = messages.required
    } else if (values?.shopName?.length > inputTextLimit) {
        errors.shopName = messages.textAreaError
    };

    if (!values?.firstName) {
        errors.firstName = messages.required;
    } else if (values?.firstName?.trim() == "") {
        errors.firstName = messages.required;
    } else if (!isNaN(values?.firstName)) {
        errors.firstName = messages.invalid
    } else if (values?.firstName?.length < 2) {
        errors.firstName = messages.required
    } else if (values?.firstName?.length > inputTextLimit) {
        errors.firstName = messages.textAreaError
    };

    if (!values?.lastName) {
        errors.lastName = messages.required;
    } else if (values?.lastName?.trim() == "") {
        errors.lastName = messages.required;
    } else if (!isNaN(values?.lastName)) {
        errors.lastName = messages.invalid
    } else if (values?.lastName?.length < 2) {
        errors.lastName = messages.required
    } else if (values?.lastName?.length > inputTextLimit) {
        errors.lastName = messages.textAreaError
    };

    if (!values?.description) {
        errors.description = messages.required;
    } else if (values?.description?.trim() == "") {
        errors.description = messages.required;
    } else if (!isNaN(values?.description)) {
        errors.description = messages.invalid
    } else if (values?.description?.length < 4) {
        errors.description = messages.required
    } else if (values?.description?.length > textAreaLimit) {
        errors.description = messages.textAreaError1
    };

    if (!values?.email) {
        errors.email = messages.required;
    } else if (!isNaN(values.email)) {
        errors.email = messages.invalid
    } else if (!values?.email?.includes('@')) {
        errors.email = messages.emailInvalid
    } else if (!/^(([^<>()[\]\\.,;.!-#$_&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = messages.emailInvalid
    } else if (values?.email?.length < 4) {
        errors.email = messages.required
    } else if (values?.email?.length > 35) {
        errors.email = messages.exceedLimit
    };

    if (values?.password?.toString().trim() === '') {
        errors.password = messages.required;
    } else if (values?.password?.length < 8) {
        errors.password = messages.passwordInvalid;
    } else if (values?.password?.length > textAreaLimit) {
        errors.password = messages.textAreaError
    };


    if (!values?.zipcode) {
        errors.zipcode = messages.required;
    } else if (values?.zipcode?.trim() == "") {
        errors.zipcode = messages.required;
    } else if (values?.zipcode?.length < 4) {
        errors.zipcode = messages.invalid
    } else if (values?.zipcode?.length > textAreaLimit) {
        errors.zipcode = messages.textAreaError
    };

    if (!values?.phoneNumber) {
        errors.phoneNumber = messages.required;
    } else if (values?.phoneNumber?.trim() == "") {
        errors.phoneNumber = messages.required;
    } else if (isNaN(values?.phoneNumber)) {
        errors.phoneNumber = messages.required;
    } else if (values?.phoneNumber?.length < 4) {
        errors.phoneNumber = messages.required
    } else if (values?.phoneNumber?.length > textAreaLimit) {
        errors.phoneNumber = messages.textAreaError
    };

    if (!values?.preferredCurrency) {
        errors.preferredCurrency = messages.required;
    }

    if (!values?.priceRange) {
        errors.priceRange = messages.required;
    }




    if (!values?.address) {
        errors.address = messages.required;
    }

    if (values?.categoryType?.length == 0) {
        errors.categoryType = messages.required;
    }

    if (!values.isTakeAway && !values.isDoorStepDelivery) {
        errors.isTakeAway = messages.validateDietary;
    }

    return errors;
};

export default validate