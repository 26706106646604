import updateSiteSettings from '../../../actions/siteadmin/SiteSettings/updateSiteSettings'

async function submit(values, dispatch) {

    await dispatch(
        updateSiteSettings(
            values.siteName,
            values.siteTitle,
            values.metaDescription,
            values.facebookLink,
            values.twitterLink,
            values.instagramLink,
            values.metaKeyword,
            values.homeLogo,
            values.youtubeLink,
            values.currency,
            values.modifiers,
            values.favicon,
            values.contactNumber,
            values.contactEmail,
            values.contactSkype,
            values.notificationInterval,
            values.ogImage
        )
    )
}

export default submit;