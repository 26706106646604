import { inputTextLimit } from '../../../helpers/inputTextLimit';
import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.safetyGridTitle2) {
        errors.safetyGridTitle2 = messages.required;
    } else if (values.safetyGridTitle2.trim() == "") {
        errors.safetyGridTitle2 = messages.required;
    } else if (values.safetyGridTitle2.length < 2) {
        errors.safetyGridTitle2 = messages.minExceedLimit
    } else if (values?.safetyGridTitle2?.length > inputTextLimit) {
        errors.safetyGridTitle2 = messages.textAreaError
    }

    if (!values.safetyGridContent2) {
        errors.safetyGridContent2 = messages.required;
    } else if (values.safetyGridContent2.trim() == "") {
        errors.safetyGridContent2 = messages.required;
    } else if (values.safetyGridContent2.length < 2) {
        errors.safetyGridContent2 = messages.minExceedLimit
    } else if (values.safetyGridContent2.length > 400) {
        errors.safetyGridContent2 = messages.exceedLimit400
    }

    if (!values.safetyGridLink3) {
        errors.safetyGridLink3 = messages.required
    } else if (values.safetyGridLink3.trim() == '') {
        errors.safetyGridLink3 = messages.required
    } else if (values.safetyGridLink3) {
        if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.safetyGridLink1)) {
            errors.safetyGridLink3 = messages.validUrl;
        }
    }

    if (!values.safetyGridLink4) {
        errors.safetyGridLink4 = messages.required
    } else if (values.safetyGridLink4.trim() == '') {
        errors.safetyGridLink4 = messages.required
    } else if (values.safetyGridLink4) {
        if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.safetyGridLink2)) {
            errors.safetyGridLink4 = messages.validUrl;
        }
    }

    if (!values.driverAppBgColor) {
        errors.driverAppBgColor = messages.required;
    } else if (values.driverAppBgColor && values.driverAppBgColor.trim().toString() == "") {
        errors.driverAppBgColor = messages.required;
    } else if (!/^#([0-9a-f]{3}){1,2}$/i.test(values.driverAppBgColor)) {
        errors.driverAppBgColor = messages.invalid;
    }

    return errors;
};

export default validate;