exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._224Ys {\n    padding: 7px 10px !important;\n}\n\n._2Zjvc {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n\n._89d8k {\n    color: #FB5609 !important;\n    text-decoration: none !important;\n}\n\n.OXEv3 {\n    opacity: 0.5;\n}\n\n._3Qr5x {\n    text-align: center !important;\n}\n\n.Rz4uA {\n    padding: 0 !important;\n}", ""]);

// exports
exports.locals = {
	"btnPadding": "_224Ys",
	"iconWidth": "_2Zjvc",
	"linkColor": "_89d8k",
	"inactive": "OXEv3",
	"alignCenter": "_3Qr5x",
	"noPadding": "Rz4uA"
};