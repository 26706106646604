import messages from '../../../../locale/messages';
import { inputTextLimit, textAreaLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.deliveryName) {
        errors.deliveryName = messages.required;
    } else if (values.deliveryName && values.deliveryName.trim().toString() == "") {
        errors.deliveryName = messages.required;
    } else if (values.deliveryName.length > inputTextLimit) {
        errors.deliveryName = messages.textAreaError;
    }

    if (!values.deliveryDescription) {
        errors.deliveryDescription = messages.required;
    } else if (values.deliveryDescription && values.deliveryDescription.trim().toString() == "") {
        errors.deliveryDescription = messages.required;
    } else if (values.deliveryDescription.length > textAreaLimit) {
        errors.deliveryDescription = messages.textAreaError1;
    }

    if (!values.status) {
        errors.status = messages.required;
    }

    if (!values.textColor) {
        errors.textColor = messages.required;
    } else if (values.textColor && values.textColor.trim().toString() == "") {
        errors.textColor = messages.required;
    } else if (!/^#([0-9a-f]{3}){1,2}$/i.test(values.textColor)) {
        errors.textColor = messages.invalid;
    }

    if (!values.backgroundColor) {
        errors.backgroundColor = messages.required;
    } else if (values.backgroundColor && values.backgroundColor.trim().toString() == "") {
        errors.backgroundColor = messages.required;
    } else if (!/^#([0-9a-f]{3}){1,2}$/i.test(values.backgroundColor)) {
        errors.backgroundColor = messages.invalid;
    }

    if (!values.iconBackgroundColor) {
        errors.iconBackgroundColor = messages.required;
    } else if (values.backgroundColor && values.iconBackgroundColor.trim().toString() == "") {
        errors.iconBackgroundColor = messages.required;
    } else if (!/^#([0-9a-f]{3}){1,2}$/i.test(values.iconBackgroundColor)) {
        errors.iconBackgroundColor = messages.invalid;
    }

    return errors;
};

export default validate