import messages from '../../../../locale/messages';
import { inputTextLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.menuName) {
        errors.menuName = messages.required;
    } else if (!isNaN(values.menuName)) {
        errors.menuName = messages.required
    } else if (values.menuName.trim() == "") {
        errors.menuName = messages.required;
    } else if (values.menuName.length > inputTextLimit) {
        errors.menuName = messages.textAreaError
    };



    return errors;
};

export default validate