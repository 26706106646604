import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import axios from 'axios';

import Loader from '../../Common/Loader/Loader';

import { closeLogoutModal } from '../../../actions/modalActions';
import { api, logoUploadDir } from '../../../config';
import { setLoaderComplete, setLoaderStart } from '../../../actions/loader/loader';
import messages from '../../../locale/messages';

import s from './LogoutForm.css';
import rs from '../../storeCommon.css';

class LogoutForm extends React.Component {
	static propTypes = {
		formatMessage: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = {
			logoutLoading: false,
		};
	}

	static defaultProps = {
		logoutLoading: false,
	};

	logout = async () => {
		const { setLoaderStart, setLoaderComplete } = this.props;
		setLoaderStart('storeLogout')
		const { data } = await axios.post('/shopLogout');
		if (data?.status == 200) {
			setLoaderComplete('storeLogout')
			window.location.assign('/store')
		}
	}

	render() {
		const { closeLogoutModal, logo, logoWidth, logoHeight, siteName, logoutLoading } = this.props;

		const { formatMessage } = this.props.intl;
		return (
			<div className={s.textAlignCenter}>
				<div className={cx(s.padding, rs.space3)}>
					<div className={s.storeLogoutbrand}>
						<img
							src={api.apiEndpoint + logoUploadDir + logo}
							className={s.storeLogoutLogo}
							alt={siteName}
						/>
					</div>
					<h3 className={cx(s.titleText, rs.spaceTop2)}><FormattedMessage {...messages.logOutLabel} /></h3>
					<p className={s.descriptionText}><FormattedMessage {...messages.logoutConfirm} /></p>
				</div>
				<Button className={cx(rs.button, s.modalCaptionLink, rs.space3)} onClick={() => closeLogoutModal()}>
					<FormattedMessage {...messages.goBackHome} />
				</Button>
				<div className={cx(s.formSection, rs.space5)}>
					<Loader
						type="button"
						label={formatMessage(messages.yesLogOutLabel)}
						show={logoutLoading}
						buttonType="button"
						handleClick={this.logout}
						className={cx(rs.button, rs.btnPrimary, rs.fullWidth)}
						disabled={logoutLoading}
						isSuffix
					/>
				</div>
			</div>
		)
	}
}

const mapState = (state) => ({
	logoutLoading: state?.loader?.storeLogout,
	logo: state?.siteSettings?.data?.homeLogo,
	logoHeight: state?.siteSettings?.data?.logoHeight,
	logoWidth: state?.siteSettings?.data?.logoWidth,
	siteName: state?.siteSettings?.data?.siteName,
});

const mapDispatch = {
	closeLogoutModal,
	setLoaderComplete,
	setLoaderStart
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(LogoutForm)));