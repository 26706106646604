import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import Loader from '../../Common/Loader';
import SwitchButton from './Switch';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';

import { siteSettings } from '../../../actions/siteadmin/SiteSettings/siteSettings';

import messages from '../../../locale/messages';
import submit from './submit';
import validate from './validate';
import { api, logoUploadDir, faviconUploadDir, siteUrl, ogImageUploadDir } from '../../../config';

import s from './SiteSettingsForm.css';
export class SiteSettingsForm extends Component {

  static defaultProps = {
    loading: false,
    updateSiteSettings: false,
    faviconimageLoader: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(s.space2, 'positionRelative')}>
        <label>{label}</label>
        <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderAddonFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength, addonLabel }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder', 'postAddon')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
            <InputGroup.Text>{addonLabel}</InputGroup.Text>
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(s.space2, 'positionRelative')}>
        <label>{label}</label>
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          as="textarea"
          rows='4'
        >
          {children}
        </FormControl>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
    return (
      <Form.Group className={cx(s.space2, 'positionRelative')}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
          {children}
        </Form.Control>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
      </Form.Group>
    )
  }
  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    return (
      <div className={cx('inputFormAddon', 'addonBorder', 'positionRelative')}>
        <Form.Group>
          <label>{label}</label>
          <InputGroup>
            <InputGroup.Append>
              <InputGroup.Text>
                V
              </InputGroup.Text>
            </InputGroup.Append>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
        </Form.Group>
      </div>
    )
  }

  renderSwitch = ({ input, meta: { touched, error }, switchOnLabel, switchOffLabel }) => {
    input.value = input.value == "0" ? false : true;
    return (
      <SwitchButton
        {...input}
        defaultValue={input.value}
        switchOnLabel={switchOnLabel}
        switchOffLabel={switchOffLabel}
      />
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, submitting, updateSiteSettings, favicon, logoImageLoader, faviconImageLoader, ogImageLoader, ogImage } = this.props;
    const { data: { getCurrencies } } = this.props;

    return (
      <Container fluid className={s.spaceTop5}>
        <form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className={s.space5}>
              <div className={s.siteAdminFormGrid}>
                <div className={cx(s.profileImgSection, s.space2)}>
                  <ImageUploadComponent
                    className={cx(s.btnSecondary, s.profileNoPadding)}
                    subTextClass={s.subText}
                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                    defaultMessage={formatMessage(messages.chooseFile)}
                    loaderName={'logoImageLoader'}
                    postUrl={api.apiEndpoint + '/uploadLogoImage'}
                    loader={logoImageLoader}
                    fieldName={'homeLogo'}
                    formName={'SiteSettingsForm'}
                    imageSrc={logo ? api.apiEndpoint + logoUploadDir + logo : null}
                    label={formatMessage(messages.logo)}
                  />
                </div>
                <div className={cx(s.profileImgSection, s.space2)}>
                  <ImageUploadComponent
                    className={cx(s.btnSecondary, 'fileNoPadding')}
                    subTextClass={s.subText}
                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                    defaultMessage={formatMessage(messages.chooseFile)}
                    loaderName={'faviconImageLoader'}
                    postUrl={siteUrl + '/uploadFavicon'}
                    loader={faviconImageLoader}
                    fieldName={'favicon'}
                    formName={'SiteSettingsForm'}
                    imageSrc={favicon ? siteUrl + faviconUploadDir + favicon : null}
                    label={formatMessage(messages.favIconlogoLabel)}
                  />
                </div>
                <div className={cx(s.profileImgSection, s.space2)}>
                  <ImageUploadComponent
                    className={cx(s.btnSecondary, 'fileNoPadding')}
                    subTextClass={s.subText}
                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                    defaultMessage={formatMessage(messages.chooseFile)}
                    loaderName={'ogImageLoader'}
                    postUrl={api.apiEndpoint + '/uploadOgImage'}
                    loader={ogImageLoader}
                    fieldName={'ogImage'}
                    formName={'SiteSettingsForm'}
                    imageSrc={api.apiEndpoint + ogImageUploadDir + ogImage}
                    label={formatMessage(messages.ogImageLabel)}
                    toolTip={formatMessage(messages.ogImageTooltip)}
                    labelContainerClass={"tooltipLabelContainer"}
                    isBottom={true}
                  />
                </div>
              </div>
              <div className={s.siteAdminInputGridContainer}>
                <Field name="siteName" type="text" component={this.renderFormControl} label={formatMessage(messages.siteNameLabel)} />
                <Field name="siteTitle" type="text" component={this.renderFormControl} label={formatMessage(messages.siteTitleLabel)} />
                <Field name="metaKeyword" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaKeywordLabel)} />
                <Field name="metaDescription" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaDescriptionLabel)} />
                <Field name="facebookLink" type="text" component={this.renderFormControl} label={formatMessage(messages.facebookURLLabel)} />
                <Field name="twitterLink" type="text" component={this.renderFormControl} label={formatMessage(messages.twitterURLLabel)} />
                <Field name="instagramLink" type="text" component={this.renderFormControl} label={formatMessage(messages.instagramURLLabel)} />
                <Field name="youtubeLink" type="text" component={this.renderFormControl} label={formatMessage(messages.youtubeURLLabel)} />
              </div>
              <div className={s.siteAdminFormGrid}>
                <Field name="contactNumber" type="text" component={this.renderFormControl} label={formatMessage(messages.contactNumber)} />
                <Field name="contactEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.contactEmail)} />
                <Field name="contactSkype" type="text" component={this.renderFormControl} label={formatMessage(messages.contactSkype)} />
              </div>
              <div className={s.siteAdminInputGridContainer}>
                <Field name="notificationInterval" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.notificationInterval)} addonLabel={formatMessage(messages.minutes)} />
                <Field
                  name="currency"
                  placeholder={formatMessage(messages.currency)}
                  component={this.renderSelectField}
                  label={formatMessage(messages.currency)}
                  labelClass={s.labelText}
                  fieldClass={cx(s.formControlSelect, s.formControlInput)}
                >
                  <option value={""}>{formatMessage(messages.currency)}</option>
                  {
                    getCurrencies && getCurrencies.map((item, index) => {
                      return item.isEnable == 1 && <option key={index} value={item.symbol}>{item.symbol}</option>
                    })
                  }
                </Field>
                <Form.Group className={s.formGroup}>
                  <div className={'positionRelative'}>
                    <label>{formatMessage(messages.modifiers)}</label>
                    <Field name="modifiers" className={cx(s.formControlSelect, s.formControlInput, 'formControlInputRtl')} component="select">{formatMessage(messages.forceUpdate)}
                      <option value="1">{formatMessage(messages.active)}</option>
                      <option value="0">{formatMessage(messages.inactive)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </div>
              <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                <Loader
                  type={"button"}
                  label={formatMessage(messages.submitButton)}
                  show={updateSiteSettings || submitting}
                  buttonType={'submit'}
                  className={cx(s.button, s.btnPrimary)}
                  disabled={updateSiteSettings || submitting}
                  isSuffix={true}
                />
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props;
  refetch && await refetch()
  dispatch(siteSettings())
}

SiteSettingsForm = reduxForm({
  form: 'SiteSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(SiteSettingsForm);

const selector = formValueSelector('SiteSettingsForm')
const mapState = (state) => ({
  logo: selector(state, 'homeLogo'),
  ogImage: selector(state, 'ogImage'),
  appForceUpdate: selector(state, 'appForceUpdate'),
  favicon: selector(state, 'favicon'),
  updateSiteSettings: state.siteSettings.updateSiteSettings,
  logoImageLoader: state.loader.logoImageLoader,
  faviconImageLoader: state.loader.faviconImageLoader,
  ogImageLoader: state.loader.ogImageLoader
})

const mapDispatch = {
  siteSettings
}

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(gql`query {
      getCurrencies {
        id
        symbol
        isEnable
      }
    }`, {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
  )(SiteSettingsForm));