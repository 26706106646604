import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Container from 'react-bootstrap/Container';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent.js';

import validate from './validate';
import submit from './submit';
import messages from '../../../locale/messages';
import { api, homepageUploadDir } from '../../../config';

import s from './AboutSettingsForm.css';
export class AboutSettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
				/>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	render() {
		const { handleSubmit, aboutGridImage1, aboutGridImage2, aboutGridImage3, loading, submitting, bannerOneImage, bannerTwoImage, bannerThreeImage } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<Container fluid>
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.howItWorks)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<div className={s.blackCenterSection}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<Form.Group className={s.space3}>
										<div>
											<Field name="aboutGridTitle5" type="text" component={this.renderField} label={formatMessage(messages.titleLabel)} />
										</div>
									</Form.Group>
								</Col>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'bannerOneImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={bannerOneImage}
												fieldName={'aboutGridImage1'}
												formName={'AboutSettingsForm'}
												imageSrc={aboutGridImage1 ? api.apiEndpoint + homepageUploadDir + aboutGridImage1 : null}
												label={formatMessage(messages.homeImage1)}
											/>
										</div>
									</Form.Group>
									<Field name="aboutGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.bannerImage1DescriptionLabel)} />
									<Field name="aboutGridContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionLabel)} />
								</Col>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'bannerTwoImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={bannerTwoImage}
												fieldName={'aboutGridImage2'}
												formName={'AboutSettingsForm'}
												imageSrc={aboutGridImage2 ? api.apiEndpoint + homepageUploadDir + aboutGridImage2 : null}
												label={formatMessage(messages.homeImage2)}
											/>
										</div>
									</Form.Group>
									<Field name="aboutGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.bannerImage2DescriptionLabel)} />
									<Field name="aboutGridContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionLabel)} />
								</Col>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'bannerThreeImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={bannerThreeImage}
												fieldName={'aboutGridImage3'}
												formName={'AboutSettingsForm'}
												imageSrc={aboutGridImage2 ? api.apiEndpoint + homepageUploadDir + aboutGridImage3 : null}
												label={formatMessage(messages.homeImage3)}
											/>
										</div>
									</Form.Group>
									<Field name="aboutGridTitle3" type="text" component={this.renderField} label={formatMessage(messages.bannerImage3DescriptionLabel)} />
									<Field name="aboutGridContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionLabel)} />
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
									<Form.Group className={s.noMargin}>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</div>
					</Form>
				</Container>
			</div>
		)
	}
}

AboutSettingsForm = reduxForm({
	form: 'AboutSettingsForm',
	onSubmit: submit,
	validate
})(AboutSettingsForm);

const selector = formValueSelector('AboutSettingsForm')

const mapState = (state) => ({
	aboutGridImage1: selector(state, 'aboutGridImage1'),
	aboutGridImage2: selector(state, 'aboutGridImage2'),
	aboutGridImage3: selector(state, 'aboutGridImage3'),
	loading: state.loader.AboutSettingsForm,
	bannerOneImage: state.loader.bannerOneImageLoader,
	bannerTwoImage: state.loader.bannerTwoImageLoader,
	bannerThreeImage: state.loader.bannerThreeImageLoader
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AboutSettingsForm)));