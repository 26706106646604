import messages from '../../../locale/messages';
import { inputTextLimit, textAreaLimit } from '../../../helpers/inputTextLimit';

const validate = values => {

  const errors = {}

  if (!values.content) {
    errors.content = messages.required;
  }

  if (!values.metaTitle) {
    errors.metaTitle = messages.required;
  } else if (values.metaTitle.trim() == "") {
    errors.metaTitle = messages.required
  } else if (values.metaTitle.length > inputTextLimit) {
    errors.metaTitle = messages.textAreaError
  }

  if (!values.metaDescription) {
    errors.metaDescription = messages.required;
  } else if (values.metaDescription.trim() == "") {
    errors.metaDescription = messages.required;
  } else if (values.metaDescription.length > textAreaLimit) {
    errors.metaDescription = messages.textAreaError1
  }

  return errors;
}

export default validate
