import messages from '../../../locale/messages';
import { inputTextLimit, nameTextLimit } from '../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values?.firstName) {
        errors.firstName = messages.required;
    } else if (values?.firstName?.trim() == "") {
        errors.firstName = messages.required;
    } else if (values?.firstName?.length > nameTextLimit) {
        errors.firstName = messages.inputFieldError;
    }

    if (!values?.lastName) {
        errors.lastName = messages.required;
    } else if (values?.lastName?.trim() == "") {
        errors.lastName = messages.required;
    } else if (values?.lastName?.length > nameTextLimit) {
        errors.lastName = messages.inputFieldError;
    }

    if (!values?.email) {
        errors.email = messages.required;
    } else if (!/^(([^<>()[\]\\.,;.!-#$_&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values?.email)) {
        errors.email = messages.emailInvalid;
    }

    if (values?.password?.trim() == "") {
        errors.password = messages.required;
    } else if (values?.password?.length < 8) {
        errors.password = messages.passwordInvalid;
    }

    if (!values?.phoneNumber) {
        errors.phoneNumber = messages.required;
    } else if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(values?.phoneNumber)) {
        errors.phoneNumber = messages.phoneError;
    }

    if (!values?.phoneDialCode) {
        errors.phoneDialCode = messages.required;
    }

    return errors;
};

export default validate