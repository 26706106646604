import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Row,
	Col,
	Container,
	Form,
	FormGroup,
	Button
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ModifierGroupForm.css';
import rs from '../../../../components/storeCommon.css';
import messages from '../../../../locale/messages';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import submit from './submit';
import ModifierGroupItemForm from '../ModifierGroupItemForm/ModifierGroupItemForm'
import CustomCheckbox from '../../../Common/CustomCheckbox/CustomCheckbox';
import history from '../../../../history';
import { closeItemModal } from '../../../../actions/siteadmin/modalActions';


class ModifierGroupForm extends Component {

	static defaultProps = {
		loading: false
	}

	constructor(props) {
		super(props);
		this.state = {
			defaultModifierItem: 1,
			isDisabled: false
		}
		this.handleModifierItem = this.handleModifierItem.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	componentDidMount() {
		const { from, change, formErrors, maxItemCount, initialValues } = this.props;
		if (from) {
			change('modifierFrom', 'item');
		}
		if (formErrors != undefined) {
			if (formErrors.hasOwnProperty('syncErrors')) {
				this.setState({ isDisabled: true });
			} else {
				this.setState({ isDisabled: false });
			}
		}
		if (initialValues && initialValues.maxItemCount) {
			this.setState({
				defaultModifierItem: initialValues.maxItemCount
			});
		} else {
			this.setState({
				defaultModifierItem: maxItemCount
			});
		}

	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { formErrors, maxItemCount } = nextProps;
		if (formErrors != undefined) {
			if (formErrors.hasOwnProperty('syncErrors')) {
				this.setState({ isDisabled: true });
			} else {
				this.setState({ isDisabled: false });
			}
		}
	}



	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
				{touched && error && <span className={cx(rs.errorMessage, s.errorPosition)}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	checkbox = ({ label, name, input, meta: { touched, error }, isMultiple }) => {
		const { change } = this.props;
		return (
			<FormGroup className={cx(s.formGroup)}>
				<div>
					<div className={cx(s.listStyle, s.listSectionWidth, 'textAlignRightRTL')}>
						<span className={s.checkBoxSection}>
							<CustomCheckbox
								className={'icheckbox_square-green'}
								name={name}
								value={true}
								checked={input.value == true}
								onChange={event => {
									if (isMultiple) {
										if (event) {
											change('maxItemCount', 1);
											this.setState({ defaultModifierItem: 1 })
										} else {
											change('maxItemCount', 0);
											this.setState({ defaultModifierItem: 1 })
										}
									}
									return input.onChange(event);
								}}
							/>
						</span>
						<span className={cx(s.checkBoxLabel, 'modificheckBoxLabelRTL')}>
							<label className={s.fontBold}>{label}</label>
						</span>
					</div>
				</div>

			</FormGroup>
		);
	}

	handleModifierItem(action) {
		const { change } = this.props;
		const { defaultModifierItem } = this.state;
		let modifierItem = action == 'add' ? defaultModifierItem + 1 : defaultModifierItem - 1;

		if (modifierItem <= 1000 && modifierItem >= 1) {
			this.setState({ defaultModifierItem: modifierItem })
			change('maxItemCount', modifierItem);
		}
	};

	handleCancel() {
		const { closeItemModal, from } = this.props;
		if (from) {
			closeItemModal();
		} else {
			history.push('/store/menu/modifier-groups');
		}
	}

	render() {
		const { isModifierMultiple, modifierType, initialValues, modifierID, maxItemCount, error, handleSubmit, submitting } = this.props;
		const { formatMessage } = this.props.intl;
		const { defaultModifierItem, isDisabled } = this.state;
		const withDotParser = (number) => number ? number.replace(/[^\d]/g, '') : '';

		return (
			<div className={'mainContainer modifierSelect'}>
				<Container fluid>
					<Form onSubmit={handleSubmit(submit)}>
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>{modifierID ? formatMessage(messages.editModifier) : formatMessage(messages.addModifier)}</h1>
							</Col>
						</Row>
						<div className={cx(rs.storeNewBg, rs.space5, 'addModifiPadding')}>
							<div className={cx(s.searchInputStore, 'floatRightRTL', 'textAlignRightRTL')}>
								<Field
									name="modifierName"
									type="text"
									placeholder={formatMessage(messages.modifierName)}
									component={this.renderField}
									labelClass={s.labelText}
									label={formatMessage(messages.modifierName)}
									fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
								/>
							</div>
							<div class="clearfix"></div>
							<div className={cx(s.marginTop, 'textAlignRightRTL')}>
								<h2 className={cx(s.subHeading, 'textAlignRightRTL')}>{formatMessage(messages.customerChoose)}</h2>
								<div className={cx(s.displayInlineBlock, s.exactlySelect, s.displayBlockMb, rs.space5)}>
									<Field name="modifierType" className={cx(s.activeSelect, rs.storeSelectInput, rs.borderColor)} component="select">
										<option value="1">{formatMessage(messages.exactly)}</option>
										<option value="2">{formatMessage(messages.setRange)}</option>
									</Field>
								</div>
								{modifierType === "2" && <div className={cx(s.displayInlineBlock, s.exactlyInput, s.displayBlockMb)}>
									<Field
										name="minModifierItems"
										type="text"
										component={this.renderField}
										fieldClass={cx(s.formControlInputStore, 'formControlInputStoreRTL')}
										maxLength={5}
										parse={withDotParser}
									/>
								</div>}
								<div className={cx(s.displayInlineBlock, s.exactlyInput, s.displayBlockMb, 'exactlyInputRTL')}>
									<Field
										name="maxModifierItems"
										type="text"
										component={this.renderField}
										fieldClass={cx(s.formControlInputStore, 'formControlInputStoreRTL')}
										maxLength={5}
										parse={withDotParser}
									/>
								</div>
							</div>
							<div className={s.borderBottom}>
								<Field name="isModifierRequired"
									component={this.checkbox}
									label={formatMessage(messages.chooseModifier)}
								/>
								<Field name="isModifierMultiple"
									component={this.checkbox}
									label={formatMessage(messages.isMultiple)}
									isMultiple={true}
								/>
								{isModifierMultiple && <div >
									<span>{formatMessage(messages.maximunModifier)}</span>
									<div className={cx(s.incrementBtn, s.incrementBtnMb)}>
										<div className={cx(s.addSection, s.displayInlineSection)}>
											<div className={cx(s.displayInlineSection, s.vtrTop)}>
												<Button onClick={() => this.handleModifierItem('minus')} className={s.DecreseBtn}>-</Button>
											</div>
											<div className={cx(s.displayInlineSection, s.addmainSection)}>
												{maxItemCount ? maxItemCount : defaultModifierItem}
											</div>
											<div className={cx(s.displayInlineSection, s.vtrTop)}>
												<Button onClick={() => this.handleModifierItem('add')} className={s.AddBtn}>+</Button>
											</div>
										</div>
									</div>
								</div>}
							</div>
							<ModifierGroupItemForm />
						</div>
						<div className={cx(s.displayInlineBlock, s.searchTopBtn, 'textAlignLeftRTL')}>
							<Button className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBlock, s.modifierGroupBtn, 'modifierGroupBtnRTL')} onClick={() => this.handleCancel()}>
								<FormattedMessage {...messages.cancel} />
							</Button>
							<Button className={cx(rs.button, rs.btnPrimary, s.displayInlineBlock)} type="submit" disabled={submitting || isDisabled}>
								<FormattedMessage {...messages.save} />
							</Button>
						</div>
					</Form>
				</Container>
			</div>
		);
	}
};

ModifierGroupForm = reduxForm({
	form: 'ModifierGroupForm',
	validate
})(ModifierGroupForm);

const selector = formValueSelector('ModifierGroupForm');

const mapState = (state) => ({
	isModifierMultiple: selector(state, 'isModifierMultiple'),
	modifierType: selector(state, 'modifierType'),
	maxItemCount: selector(state, 'maxItemCount'),
	modifierID: selector(state, 'id'),
	formErrors: state.form.ModifierGroupForm,
});

const mapDispatch = {
	closeItemModal,
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ModifierGroupForm)));