import messages from '../../../locale/messages';
import { inputTextLimit } from '../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.homeSectionTitle1) {
        errors.homeSectionTitle1 = messages.required;
    } else if (values.homeSectionTitle1.trim() == "") {
        errors.homeSectionTitle1 = messages.required;
    } else if (values?.homeSectionTitle1?.length > inputTextLimit) {
        errors.homeSectionTitle1 = messages.textAreaError;
    }

    // if (!values.homeSectionDescription) {
    //     errors.homeSectionDescription = messages.required;
    // } else if (values.homeSectionDescription.trim() == "") {
    //     errors.homeSectionDescription = messages.required;
    // } else if (values.homeSectionDescription.length < 2 ) {
    //     errors.homeSectionDescription = messages.minExceedLimit
    // } else if (values.homeSectionDescription.length > 400 ) {
    //     errors.homeSectionDescription = messages.exceedLimit400
    // }

    

    return errors;
};

export default validate;