import { inputTextLimit } from '../../../helpers/inputTextLimit';
import messages from '../../../locale/messages';


const validate = values => {
    const errors = {};

    if (!values.citySectionTitle1) {
        errors.citySectionTitle1 = messages.required;
    } else if (values.citySectionTitle1.trim() == "") {
        errors.citySectionTitle1 = messages.required;
    } else if (values?.citySectionTitle1?.length > inputTextLimit) {
        errors.citySectionTitle1 = messages.textAreaError;
    }

    // if (!values.citySectionContent1) {
    //     errors.citySectionContent1 = messages.required;
    // } else if (values.citySectionContent1.trim() == "") {
    //     errors.citySectionContent1 = messages.required;
    // } else if (values.citySectionContent1.length < 2 ) {
    //     errors.citySectionContent1 = messages.minExceedLimit
    // } else if (values.citySectionContent1.length > 400 ) {
    //     errors.citySectionContent1 = messages.exceedLimit400
    // }



    return errors;
};

export default validate;