import React from 'react';
import s from './VerifyPhoneNumberForm.css';
import rs from '../../../storeCommon.css';
import cx from 'classnames';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
    Button,
    Form,
    FormControl
} from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';
import { change, formValueSelector } from 'redux-form';
import Loader from '../../../Common/Loader/Loader';
import { openSmsVerificationModal, closeSmsVerificationModal } from '../../../../actions/shop/smsVerification/smsVerificationModaAction';
import checkOtp from '../../../../actions/shop/smsVerification/checkOtp';

class VerifyPhoneNumberForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            verificationCode: '',
            submitting: false,
            error: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleSubmit() {
        const { formatMessage } = this.props.intl;
        const { checkOtp, change } = this.props;
        const { verificationCode } = this.state;
        let error = null, submitting = false;
        if (!verificationCode) {
            error = {
                verificationCode: formatMessage(messages.required)
            };
        } else if (isNaN(verificationCode)) {
            error = {
                verificationCode: formatMessage(messages.required)
            };
        }

        submitting = (error === null) ? true : false;

        this.setState({
            submitting,
            error
        });
        const checkVerificationCode = await checkOtp(verificationCode);
        if (checkVerificationCode) {
            await change("EditProfileForm", "isPhoneVerified", true);
        } else if (error === null && !checkVerificationCode) {
            error = {
                verificationCode: 'We were unable to validate your phone number. Please try again.'
            };
        }

        if (this.refs.verifyPhoneNumberForm) {
            this.setState({ submitting: false, error });
        }

    }

    render() {
        const { phoneDialCode, phoneNumber, closeSmsVerificationModal } = this.props;
        const { verificationCode, submitting, error } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div ref="verifyPhoneNumberForm" className={rs.phoneNumberSection}>
                <Form.Group className={rs.space1}>
                    <label className={s.labelColor}>
                        <FormattedMessage {...messages.weHaveSentVerificationCode} />
                        {' ' + phoneDialCode}{' ' + phoneNumber}
                    </label>
                </Form.Group>
                <Form.Group>
                    <label className={s.labelColor}>
                        <FormattedMessage {...messages.verificationCodeLabel} />
                    </label>
                    <FormControl
                        name={'verificationCode'}
                        placeholder={''}
                        label={formatMessage(messages.verificationCode)}
                        type={'text'}
                        onChange={this.handleChange}
                        maxLength={4}
                        className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
                    />
                    {error && error.verificationCode && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{error.verificationCode}</span>}
                </Form.Group>
                <Form.Group className={s.textVrify}>
                    <Loader
                        type={"button"}
                        label={formatMessage(messages.verify)}
                        show={submitting}
                        buttonType={'button'}
                        handleClick={this.handleSubmit}
                        className={cx(rs.button, rs.btnPrimary)}
                    />
                    <a
                        href={'javascript:void(0)'}
                        onClick={closeSmsVerificationModal}
                        className={cx(rs.button, rs.btnPrimaryBorder, s.vtrMiddle)}
                    >
                        <FormattedMessage {...messages.cancel} />
                    </a>
                </Form.Group>
            </div>
        )
    }
}

const selector = formValueSelector("EditProfileForm");

const mapState = (state) => ({
    phoneNumber: selector(state, 'phoneNumber'),
    phoneDialCode: selector(state, 'phoneDialCode')
});

const mapDispatch = {
    openSmsVerificationModal,
    closeSmsVerificationModal,
    checkOtp,
    change
};
export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(VerifyPhoneNumberForm)));