import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
    Button,
    Form,
    FormControl,
    Col
} from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Loader from '../../../Common/Loader';
import sendVerification from '../../../../actions/shop/smsVerification/sendVerification';
import checkOtp from '../../../../actions/shop/smsVerification/checkOtp';
import { openSmsVerificationModal, closeSmsVerificationModal } from '../../../../actions/shop/smsVerification/smsVerificationModaAction';
import AddPhoneNumberForm from '../AddPhoneNumberForm/AddPhoneNumberForm';
import VerifyPhoneNumberForm from '../VerifyPhoneNumberForm/VerifyPhoneNumberForm';
import showToaster from '../../../../helpers/toasterMessages/showToaster';
//Images
import closeIcon from '../../../../../public/StoreIcon/delete.png';
import doneIcon from '../../../../../public/StoreIcon/done.png';
import s from './PhoneNumberVerification.css';
import rs from '../../../storeCommon.css';

class PhoneNumberVerification extends React.Component {

    static defaultProps = {
        modalStatus: false,
        modalFormType: 'addPhoneNumberForm'
    }

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            phoneDialCode: '',
            phoneCountryCode: '',
            phoneStatus: '',
            userId: '',
            loader: false,
            verificationCode: '',
            isVerificationCodeSend: false,
            addPhoneNumberForm: true,
            verificationForm: false
        }
        this.handleVerify = this.handleVerify.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentWillMount() {
        const { phoneData } = this.props;

        this.setState({
            phoneNumber: phoneData.phoneNumber,
            phoneDialCode: phoneData.phoneDialCode,
            phoneCountryCode: phoneData.phoneCountryCode,
            phoneStatus: phoneData.phoneStatus,
            userId: phoneData.userId
        })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { modalFormType, phoneNumber, phoneDialCode } = newProps;
        this.setState({
            phoneNumber,
            phoneDialCode
        })
        if (modalFormType == 'verifyPhoneNumberForm') {
            this.setState({
                addPhoneNumberForm: false,
                verificationForm: true
            })
        } else if (modalFormType == 'addPhoneNumberForm') {
            this.setState({
                addPhoneNumberForm: true,
                verificationForm: false,
                phoneStatus: false
            })
        }

    }

    async handleVerify() {
        const { phoneNumber, phoneDialCode, userId } = this.state;
        const { sendVerification } = this.props;
        if (!phoneNumber && phoneNumber == "") {
            showToaster({ messageId: 'enterPhoneNumber', toasterType: 'error' })
        }
        this.setState({
            loader: true
        });
        const { status, errorMessage } = await sendVerification(phoneNumber, phoneDialCode, userId);
        if (status == 200) {
            this.setState({
                loader: false,
                isVerificationCodeSend: true,
                phoneStatus: true
            });

        } else {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: errorMessage })
            this.setState({
                loader: false
            });
        }

    }

    async handleSubmit() {
        const { checkOtp, change } = this.props;
        const { verificationCode, phoneNumber } = this.state;
        this.setState({
            loader: true
        });
        const checkVerificationCode = await checkOtp(verificationCode);
        if (checkVerificationCode) {
            this.setState({
                isVerificationCodeSend: false,
                phoneStatus: true
            });
            await change("EditProfileForm", "isPhoneVerified", true);
        }
        this.setState({
            loader: false
        });

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async handleRemove() {
        const { change, openSmsVerificationModal } = this.props;
        await change("EditProfileForm", "isPhoneVerified", false);
        openSmsVerificationModal("addPhoneNumberForm");

    }

    renderVerifyPhoneNumber() {
        const { loader, phoneNumber, phoneDialCode, openSmsVerificationModal } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className={s.phoneNumberFlex}>
                <div className={cx(s.phoneNumberInput, s.phoneNumberDisable, "textAlignRightRTL")}>
                    {' ' + phoneDialCode}{' ' + phoneNumber}
                </div>
                <div className={s.phoneBtnFlex}>
                    <div className={cx(s.flexGrow)}>
                        <Loader
                            type={"button"}
                            label={formatMessage(messages.verify)}
                            show={loader}
                            buttonType={'button'}
                            handleClick={this.handleVerify}
                            className={cx(rs.btnPrimary, s.fullWidth)}
                        />
                    </div>
                    <div>
                        <a href="javascript:void(0)" onClick={() => this.handleRemove()}>
                            <img src={closeIcon} className={s.removeBtnIcon} />
                        </a>
                    </div>
                </div>

            </div>

        )
    }

    renderConfirmedPhoneNumber() {
        const { phoneNumber, phoneDialCode } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={s.phoneNumberFlex}>
                <div className={cx(s.phoneNumberInput, s.phoneNumberDisable, "textAlignRightRTL")}> {' ' + phoneDialCode}{' ' + phoneNumber}</div>
                <div className={s.phoneBtnFlex}>
                    <p className={cx(rs.btnPrimaryBorder, s.flexGrow)}>
                        <span><img src={doneIcon} className={s.tickIcon} /></span>
                        <span className={cx(s.confirmedIcon)}>
                            {formatMessage(messages.confirmed)}
                        </span>
                    </p>

                    <div>
                        <a href="javascript:void(0)" onClick={() => this.handleRemove()}
                            title="Remove"
                        >
                            <img src={closeIcon} className={s.removeBtnIcon} />
                        </a>
                    </div>
                </div>
                {/* <tbody>
                        <tr>
                            <th className={s.tableBoxHeader}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                   
                                </Col>
                            </th>
                            <td className={s.loaderSection}>
                                
                            </td>
                            <td className={s.deleteIconPadding}>
                                
                            </td>
                        </tr>
                    </tbody> */}

            </div>
        )

    }
    render() {
        const { phoneDialCode } = this.props;
        const { modalStatus, isVerified } = this.props;
        const { addPhoneNumberForm, verificationForm, phoneNumber } = this.state;

        return (
            <div>
                {
                    !modalStatus && !isVerified &&
                    <div>
                        {
                            this.renderVerifyPhoneNumber()
                        }
                    </div>
                }
                {
                    modalStatus && addPhoneNumberForm &&
                    <div>
                        <AddPhoneNumberForm />
                    </div>
                }
                {
                    modalStatus && !isVerified && verificationForm &&
                    <div>
                        <VerifyPhoneNumberForm
                            phoneDialCode={phoneDialCode}
                            phoneNumber={phoneNumber}
                        />
                    </div>
                }
                {
                    !modalStatus && isVerified &&
                    <div>
                        {this.renderConfirmedPhoneNumber()}
                    </div>
                }
            </div>
        )

    }
}

const selector = formValueSelector('EditProfileForm');

const mapState = (state) => ({
    modalStatus: state.modalStatus.isSmsVerificationModalOpen,
    modalFormType: state.modalStatus.formType,
    isVerified: selector(state, 'isPhoneVerified'),
    phoneNumber: selector(state, 'phoneNumber'),
    phoneDialCode: selector(state, 'phoneDialCode')
})

const mapDispatch = {
    sendVerification,
    openSmsVerificationModal,
    closeSmsVerificationModal,
    checkOtp,
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(PhoneNumberVerification)));