import { inputTextLimit, textAreaLimit } from '../../../helpers/inputTextLimit';
import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.aboutGridTitle1) {
        errors.aboutGridTitle1 = messages.required;
    } else if (values.aboutGridTitle1.trim() == "") {
        errors.aboutGridTitle1 = messages.required;
    } else if (values.aboutGridTitle1.length < 2) {
        errors.aboutGridTitle1 = messages.minExceedLimit
    } else if (values?.aboutGridTitle1?.length > inputTextLimit) {
        errors.aboutGridTitle1 = messages.textAreaError
    }

    if (!values.aboutGridContent1) {
        errors.aboutGridContent1 = messages.required;
    } else if (values.aboutGridContent1.trim() == "") {
        errors.aboutGridContent1 = messages.required;
    } else if (values?.aboutGridContent1?.length > textAreaLimit) {
        errors.aboutGridContent1 = messages.textAreaError1;
    }


    if (!values.aboutGridTitle2) {
        errors.aboutGridTitle2 = messages.required;
    } else if (values.aboutGridTitle2.trim() == "") {
        errors.aboutGridTitle2 = messages.required;
    } else if (values?.aboutGridTitle2?.length > inputTextLimit) {
        errors.aboutGridTitle2 = messages.textAreaError;
    }

    if (!values.aboutGridContent2) {
        errors.aboutGridContent2 = messages.required;
    } else if (values.aboutGridContent2.trim() == "") {
        errors.aboutGridContent2 = messages.required;
    } else if (values?.aboutGridContent2?.length > textAreaLimit) {
        errors.aboutGridContent2 = messages.textAreaError1;
    }


    if (!values.aboutGridTitle3) {
        errors.aboutGridTitle3 = messages.required;
    } else if (values.aboutGridTitle3.trim() == "") {
        errors.aboutGridTitle3 = messages.required;
    } else if (values?.aboutGridTitle3?.length > inputTextLimit) {
        errors.aboutGridTitle3 = messages.textAreaError;
    }

    if (!values.aboutGridContent3) {
        errors.aboutGridContent3 = messages.required;
    } else if (values.aboutGridContent3.trim() == "") {
        errors.aboutGridContent3 = messages.required;
    } else if (values.aboutGridContent3.length > textAreaLimit) {
        errors.aboutGridContent3 = messages.textAreaError1;
    }


    if (!values.aboutGridTitle4) {
        errors.aboutGridTitle4 = messages.required;
    } else if (values.aboutGridTitle4.trim() == "") {
        errors.aboutGridTitle4 = messages.required;
    } else if (values.aboutGridTitle4.length < 2) {
        errors.aboutGridTitle4 = messages.minExceedLimit
    } else if (values.aboutGridTitle4.length > 200) {
        errors.aboutGridTitle4 = messages.exceedLimit200
    }

    if (!values.aboutGridContent4) {
        errors.aboutGridContent4 = messages.required;
    } else if (values.aboutGridContent4.trim() == "") {
        errors.aboutGridContent4 = messages.required;
    } else if (values.aboutGridContent4.length < 2) {
        errors.aboutGridContent4 = messages.minExceedLimit
    } else if (values.aboutGridContent4.length > 400) {
        errors.aboutGridContent4 = messages.exceedLimit400
    }


    if (!values.aboutGridTitle5) {
        errors.aboutGridTitle5 = messages.required;
    } else if (values.aboutGridTitle5.trim() == "") {
        errors.aboutGridTitle5 = messages.required;
    } else if (values?.aboutGridTitle5?.length > inputTextLimit) {
        errors.aboutGridTitle5 = messages.textAreaError
    }

    // if (!values.aboutGridContent5) {
    //     errors.aboutGridContent5 = messages.required;
    // } else if (values.aboutGridContent5.trim() == "") {
    //     errors.aboutGridContent5 = messages.required;
    // } else if (values.aboutGridContent5.length < 2 ) {
    //     errors.aboutGridContent5 = messages.minExceedLimit
    // } else if (values.aboutGridContent5.length > 400 ) {
    //     errors.aboutGridContent5 = messages.exceedLimit400
    // }


    if (!values.aboutGridTitle6) {
        errors.aboutGridTitle6 = messages.required;
    } else if (values.aboutGridTitle6.trim() == "") {
        errors.aboutGridTitle6 = messages.required;
    } else if (values.aboutGridTitle6.length < 2) {
        errors.aboutGridTitle6 = messages.minExceedLimit
    } else if (values.aboutGridTitle6.length > 200) {
        errors.aboutGridTitle6 = messages.exceedLimit200
    }

    if (!values.aboutGridContent6) {
        errors.aboutGridContent6 = messages.required;
    } else if (values.aboutGridContent6.trim() == "") {
        errors.aboutGridContent6 = messages.required;
    } else if (values.aboutGridContent6.length < 2) {
        errors.aboutGridContent6 = messages.minExceedLimit
    } else if (values.aboutGridContent6.length > 400) {
        errors.aboutGridContent6 = messages.exceedLimit400
    }



    return errors;
};

export default validate;