import messages from '../../../../locale/messages';
import { inputTextLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.categoryName) {
        errors.categoryName = messages.required;
    } else if (values.description && values.categoryName.trim().toString() == "") {
        errors.categoryName = messages.required;
    } else if (values.categoryName.length > inputTextLimit) {
        errors.categoryName = messages.textAreaError
    }

    if (!values.deliveryType) {
        errors.deliveryType = messages.required;
    }

    return errors;
};

export default validate