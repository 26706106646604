import messages from '../../../locale/messages'
import { inputTextLimit, textAreaLimit } from '../../../helpers/inputTextLimit';

const validate = values => {

  const errors = {}

  if (!values.siteName) {
    errors.siteName = messages.required;
  } else if (values.siteName.trim() == "") {
    errors.siteName = messages.required;
  } else if (values.siteName && values.siteName.length > inputTextLimit) {
    errors.siteName = messages.textAreaError
  }

  if (!values.siteTitle) {
    errors.siteTitle = messages.required;
  } else if (values.siteTitle.trim() == "") {
    errors.siteTitle = messages.required
  } else if (values.siteTitle && values.siteTitle.length > inputTextLimit) {
    errors.siteTitle = messages.textAreaError
  }

  if (!values.metaDescription) {
    errors.metaDescription = messages.required
  } else if (values.metaDescription.trim() == '') {
    errors.metaDescription = messages.required
  } else if (values.metaDescription && values.metaDescription.length > textAreaLimit) {
    errors.metaDescription = messages.textAreaError1
  }

  if (!values.metaKeyword) {
    errors.metaKeyword = messages.required
  } else if (values.metaKeyword.trim() == '') {
    errors.metaKeyword = messages.required
  } else if (values.metaKeyword && values.metaKeyword.length > textAreaLimit) {
    errors.metaKeyword = messages.textAreaError1
  }

  if (!values.currency) {
    errors.currency = messages.required
  }

  if (!values.twitterLink) {
    errors.twitterLink = messages.required
  } else if (values.twitterLink.trim() == '') {
    errors.twitterLink = messages.required
  } else if (values.twitterLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.twitterLink)) {
      errors.twitterLink = messages.validUrl;
    }
  }

  if (!values.youtubeLink) {
    errors.youtubeLink = messages.required
  } else if (values.youtubeLink.trim() == '') {
    errors.youtubeLink = messages.required
  } else if (values.youtubeLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.youtubeLink)) {
      errors.youtubeLink = messages.validUrl;
    }
  }

  if (!values.instagramLink) {
    errors.instagramLink = messages.required
  } else if (values.instagramLink.trim() == '') {
    errors.instagramLink = messages.required
  } else if (values.instagramLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.instagramLink)) {
      errors.instagramLink = messages.validUrl;
    }
  }

  if (!values.facebookLink) {
    errors.facebookLink = messages.required
  } else if (values.facebookLink.trim() == '') {
    errors.facebookLink = messages.required
  } else if (values.facebookLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.facebookLink)) {
      errors.facebookLink = messages.validUrl;
    }
  }

  if (!values.contactEmail) {
    errors.contactEmail = messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.contactEmail)) {
    errors.contactEmail = messages.emailInvalid;
  }

  if (!values.contactNumber) {
    errors.contactNumber = messages.required;
  } else if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(values.contactNumber)) {
    errors.contactNumber = messages.phoneError;
  }

  if (values.contactSkype && values.contactSkype.toString().trim() === '') {
    errors.contactSkype = messages.invalid;
  } else if (values?.contactSkype?.length > inputTextLimit) {
        errors.contactSkype = messages.textAreaError
  }

  if (!values.notificationInterval || values.notificationInterval.toString().trim() == "") {
    errors.notificationInterval = messages.required;
  } else if (values.notificationInterval) {
    if (isNaN(values.notificationInterval)) {
      errors.notificationInterval = messages.invalid;
    } else if (Number(values.notificationInterval) < 0.5) {
      errors.notificationInterval = messages.notificationIntervalInvalid;
    }
  }

  return errors
}

export default validate;
