import { textAreaLimit } from '../../../helpers/inputTextLimit';
import messages from '../../../locale/messages'

const validate = values => {

  const errors = {}

  if (!values.reason) {
    errors.reason = messages.reasonRequired;
  } else if (values.reason.trim() == "") {
    errors.reason = messages.reasonRequired
  } else if (values?.reason?.length > textAreaLimit) {
    errors.reason = messages.textAreaError1
  }

  return errors
}

export default validate
