import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import cx from 'classnames';
import Loader from '../Loader/Loader';
import Dropzone from '../Dropzone/Dropzone';

import updateFavIcon from '../../../actions/siteadmin/updateFavIcon';

import TrashIcon from '../../../../public/StoreIcon/dlty.svg';
import defaultIcon from '../../../../public/Icons/defaultIconAdmin.svg';
import documentDefaultImage from '../../../../public/StoreIcon/addDocumentImage.svg';
import Faq from '../../../../public/Icons/question_info.svg';


import s from './ImageUploadComponent.css';

class ImageUploadComponent extends Component {
  static propTypes = {
    label: PropTypes.string,
    imageSrc: PropTypes.string,
    loader: PropTypes.bool,
    defaultMessage: PropTypes.string,
    loaderName: PropTypes.string,
    fieldName: PropTypes.string,
    formName: PropTypes.string,
    handleDropzone: PropTypes.func,
    isRemove: PropTypes.bool,
    handleDelete: PropTypes.func,
    postUrl: PropTypes.string,
  };

  handleDropzone = (fileName, fieldName, formName) => {
    const { change, updateFavIcon } = this.props;
    change(formName, fieldName, fileName);
    if (fieldName === 'favicon') updateFavIcon(fileName);
  };

  renderTooltip = (label) => {
    const { isBottom } = this.props;
    return (
      <>
        <OverlayTrigger placement={isBottom ? "bottom" : "top"} overlay={<Popover className='imageUploadTooltipContainer'>{label}</Popover>}>
          <img src={Faq} />
        </OverlayTrigger>
      </>
    )
  }

  render() {
    const {
      label,
      imageSrc,
      loader,
      className,
      subTextClass,
      subText,
      defaultMessage,
      loaderName,
      fieldName,
      isLoaderExist,
      documentDefault,
    } = this.props;
    const {
      formName,
      handleDropzone,
      inputContainer,
      inputContainerClass,
      isRemove,
      handleDelete,
      postUrl,
      maxFiles,
      toolTip,
      labelContainerClass
    } = this.props;
    return (
      <div className={'storeLoader'}>
        {label && <label className={labelContainerClass}>{label} {toolTip && this.renderTooltip(toolTip)}</label>}

        {imageSrc && (
          <Loader show={loader} type="page">
            <div
              className={s.backgroundImg}
              style={{ backgroundImage: `url(${imageSrc})` }}
            />
          </Loader>
        )}

        {!imageSrc && !isLoaderExist && (
          <Loader show={loader} type="page">
            <div
              className={cx(s.backgroundImg, 'defaultProfileIcon')}
              style={{ backgroundImage: `url(${defaultIcon})` }}
            />
          </Loader>
        )}

        {!imageSrc && documentDefault && (
          <Loader show={loader} type="page">
            <div
              className={cx(s.backgroundImg, 'defaultProfileIcon')}
              style={{ backgroundImage: `url(${documentDefaultImage})` }}
            />
          </Loader>
        )}
        {isRemove && imageSrc && (
          <Button onClick={handleDelete} className={s.btnTrash}>
            <img src={TrashIcon} alt="Delete" />{' '}
          </Button>
        )}
        <div>
          <Dropzone
            className={className}
            subTextClass={subTextClass}
            subText={subText}
            defaultMessage={defaultMessage}
            componentConfig={{
              iconFiletypes: ['.jpg', '.png', '.svg'],
              multiple: false,
              showFiletypeIcon: false,
              postUrl,
            }}
            loaderName={loaderName}
            fieldName={fieldName}
            formName={formName}
            handleDropzone={handleDropzone || this.handleDropzone}
            inputContainer={inputContainer}
            inputContainerClass={inputContainerClass}
            maxFiles={maxFiles}
            isLoader
          />
        </div>
      </div>
    );
  }
}

const mapState = state => ({});

const mapDispatch = {
  change,
  updateFavIcon,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(ImageUploadComponent)),
);
