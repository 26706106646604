import messages from '../../../../locale/messages';
import { inputTextLimit, textAreaLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.bannerTitle1) {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.trim() == "") {
        errors.bannerTitle1 = messages.required;
    } else if (values?.bannerTitle1?.length > inputTextLimit) {
        errors.bannerTitle1 = messages.textAreaError
    }

    if (!values.bannerContent1) {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.trim() == "") {
        errors.bannerContent1 = messages.required;
    } else if (values?.bannerContent1?.length > textAreaLimit) {
        errors.bannerContent1 = messages.textAreaError1;
    } 
    return errors;
};

export default validate;