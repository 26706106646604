import { inputTextLimit } from '../../../../helpers/inputTextLimit';
import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values?.modifierName) {
        errors.modifierName = messages.required;
    } else if (values?.modifierName?.trim() == '') {
        errors.modifierName = messages.required;
    } else if (values?.modifierName?.length > inputTextLimit) {
        errors.modifierName = messages.textAreaError;
    }

    if (values?.modifierType === "1") {
        if (!values?.maxModifierItems) {
            errors.maxModifierItems = messages.required;
        } else if (values?.maxModifierItems && (isNaN(values?.maxModifierItems) || (!/^[0-9]+$/.test(values?.maxModifierItems)) || (parseFloat(values?.maxModifierItems, 10) < 1))) {
            errors.maxModifierItems = messages.invalid;
        }
    }

    if (values?.modifierType === "2") {
        if (!values?.minModifierItems) {
            errors.minModifierItems = messages.required;
        } else if (values?.minModifierItems && (isNaN(values?.minModifierItems) || (!/^[0-9]+$/.test(values?.minModifierItems)) || (parseFloat(values?.minModifierItems, 10) < 1))) {
            errors.minModifierItems = messages.invalid;
        } else if (!values?.maxModifierItems) {
            errors.maxModifierItems = messages.required;
        } else if (values?.maxModifierItems && (isNaN(values?.maxModifierItems) || (!/^[0-9]+$/.test(values?.maxModifierItems)) || (parseFloat(values?.maxModifierItems, 10) < 1))) {
            errors.maxModifierItems = messages.invalid;
        } else if (parseInt(values?.minModifierItems) >= parseInt(values?.maxModifierItems)) {
            errors.minModifierItems = messages.modifierItemValidate;
        }


    }

    if (values?.modifierItems?.length < values?.maxModifierItems) {
        errors.modifierItems = messages.required;
    }


    if (values?.modifierItems?.length > 0) {
        const itemArrayErrors = [];
        values.modifierItems.forEach((item, index) => {
            const itemErrors = {};

            // Item Price
            if (item?.modifierItemPrice?.toString().trim() == '') {
                itemErrors['modifierItemPrice'] = messages.required;
                itemArrayErrors[index] = itemErrors;
            }
            else if (item?.modifierItemPrice && (isNaN(item?.modifierItemPrice) || (!/^[0-9\.]+$/.test(item?.modifierItemPrice)))) {
                itemErrors['modifierItemPrice'] = messages.invalid;
                itemArrayErrors[index] = itemErrors;
            }

            // Item Name
            if (!item?.modifierItemName) {
                itemErrors['modifierItemName'] = messages.required;
                itemArrayErrors[index] = itemErrors;
            } else if (item?.modifierItemName?.toString().trim() == '') {
                itemErrors['modifierItemName'] = messages.required;
                itemArrayErrors[index] = itemErrors;
            } else if (item?.modifierItemName?.length > inputTextLimit) {
                itemErrors['modifierItemName'] = messages.textAreaError;
                itemArrayErrors[index] = itemErrors;
            }
        })

        if (itemArrayErrors.length) {
            errors.modifierItems = itemArrayErrors;
        }

    }



    return errors;
};

export default validate;