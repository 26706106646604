import axios from "axios";
import { getConfigSettings as query } from "../lib/graphql";
import { decode } from "./queryEncryption";

export const getConfigSettings = async ({ name }) => {

	try {
		const { data } = await axios.post('/graphql', {
			query,
			variables: {
				name: JSON.stringify(name)
			}
		}, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		});

		let settingsData = {};

		if (settingsData) {
			await Promise.all(data?.data?.getConfigSettings?.map((item, key) => {
				settingsData[item.name] = decode(item.encryptValue);
			}));
		}

		return settingsData;

	} catch (error) {
		return false;
	}
}
