import React from 'react';
import rs from '../../../../../components/storeCommon.css'
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import ConfirmationPopup from '../../../../Common/ConfirmationPopup';
import AddModifierModal from '../AddModifierModal/AddModifierModal';
import ModifierCommonType from '../ModifierCommonType/ModifierCommonType';
import Loader from '../../../../Common/Loader';
import ImageUploadComponent from '../../../../Common/ImageUploadComponent/ImageUploadComponent';
import validate from './validate';
import submit from './submit';

import deleteMenu from '../../../../../actions/shop/menu/deleteMenu';
import history from '../../../../../history';
import getExistingModifier from '../../../../../actions/shop/modifier/getExistingModifier';
import getBookedModifierGroup from '../../../../../actions/shop/modifier/getBookedModifierGroup';

import messages from '../../../../../locale/messages';
import { convert } from '../../../../../helpers/currencyConvertion';
import { getCurrencySymbol } from '../../../../../helpers/currencyConvertion';
import { openModal, closeModal, openItemModal, editItemModal } from '../../../../../actions/siteadmin/modalActions';
import { api, itemImageUploadDir } from '../../../../../config';

import EditIcon from '../../../../../../public/StoreIcon/edit.svg';
import TrashIcon from '../../../../../../public/StoreIcon/dlty.svg';
import downArrow from '../../../../../../public/StoreIcon/Dropdownwithcircle30x30.svg';
import upArrow from '../../../../../../public/StoreIcon/Uparrowwithcircle30x30.svg';
import PlusIcon from '../../../../../../public/StoreIcon/plusIcon.svg';
import DefaultImage from '../../../../../../public/StoreIcon/store-profile-default.svg';

import s from './AddItemForm.css';
class AddItemForm extends React.Component {
	static defaultProps = {
		loading: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			collapseID: [],
			modifierList: [],
		}
	}

	componentDidMount() {
		const { modifiers } = this.props;
		if (modifiers) {
			this.setState({ modifierList: modifiers });
		}
	}

	componentWillMount() {
		const { base, rates, price, profile, change } = this.props;
		if (price) {
			let convertedAmount = convert(base, rates, price, profile && profile.preferredCurrency, profile && profile.preferredCurrency);
			convertedAmount = convertedAmount && convertedAmount.toString().includes('.')
				?
				convertedAmount.toFixed(2) : convertedAmount
			change('price', convertedAmount)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { modifiers } = nextProps;
		if (modifiers) {
			this.setState({ modifierList: modifiers });
		}
	}

	handleDelete = async (id) => {
		const { change, closeModal } = this.props;
		const { modifierList } = this.state;
		let modifierData = modifierList;
		modifierData.splice(id, 1);
		await closeModal('menuDeleteModal')
		change(`modifiers`, modifierData);
	};

	handleStatus = async (index1, index2, status) => {
		const { change } = this.props;
		await change(`modifiers[${index1}].modifierGroups[${index2}].isActive`, status);
	};

	toggleCollapse = (id) => {
		const { collapseID } = this.state;
		const index = collapseID.indexOf(id);
		if (index > -1) {
			collapseID.splice(index, 1);
			this.setState({ collapseID });
		} else {
			collapseID.push(id);
			this.setState({ collapseID });
		}
	}

	handleModifierItem = async (index, modifierGroupId) => {
		const { getBookedModifierGroup, openModal } = this.props;
		let { data } = await getBookedModifierGroup(modifierGroupId);
		if (data.length > 0) {
			openModal('menuDeleteModal', { id: index, checkBookedModifier: true });
		} else {
			openModal('menuDeleteModal', { id: index, checkBookedModifier: false });
		}
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space5, 'positionRelative')}>
				<label>{label}</label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxlength} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderTextAreaField = ({ input, label, type, meta: { touched, error }, children, labelClass, fieldClass, placeholder, maxLength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space5, 'positionRelative')}>
				<label>{label}</label>
				<Form.Control as="textarea"  {...input} placeholder={placeholder} type={type} className={cx(fieldClass, rs.textAreaField)} maxLength={maxLength} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderPrice = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, prefix, prefixLabel }) => {
		const { profile, currentLocale } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={'retaurantInputFormAddon'}>
				<Form.Group className={cx(rs.space5, 'positionRelative')}>
					{prefix && <label className={'accordionLabel'}>{label}</label>}
					{!prefix && <label>{label}</label>}
					<InputGroup>
						{prefix && <InputGroup.Append>
							<InputGroup.Text>{getCurrencySymbol(profile && profile.preferredCurrency, currentLocale)}</InputGroup.Text>
						</InputGroup.Append>}
						<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
						{!prefix && <InputGroup.Append>
							<InputGroup.Text>{prefixLabel}</InputGroup.Text>
						</InputGroup.Append>}
					</InputGroup>
					{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
				</Form.Group>
			</div>
		)
	}

	renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space5, 'positionRelative', 'store')}>
				<label>{label}</label>
				<Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	handleCancel = () => {
		const { menuId } = this.props;
		history.push('/store/menu/' + menuId + '/item');
	}

	render() {
		const { error, handleSubmit, submitting, dispatch, loading, id, image, menuId, subMenuId, imageLoader } = this.props;
		const { openModal, closeModal, menuDeleteModal, openItemModal, getExistingModifier, modifierGroupId, editItemModal, modalData, isEnableAddon } = this.props;
		const { collapseID, modifierList } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<div class="mainContainer">
				<AddModifierModal from={"item"} />
				<div>
					<ConfirmationPopup
						modalStatus={menuDeleteModal}
						checkBookedModifier={modalData && modalData.checkBookedModifier}
						title={formatMessage(messages.deleteModifier)}
						body={formatMessage(messages.deleteConfirmation)}
						bodyModifier={formatMessage(messages.deleteConfirmationModifier)}
						closeModal={() => closeModal('menuDeleteModal')}
						popupButtonLeftName={formatMessage(messages.cancelButton)}
						popupButtonRightName={formatMessage(messages.deleteAction)}
						popupButtonLeftFunction={() => closeModal('menuDeleteModal')}
						popupButtonRightFunction={() => this.handleDelete(modalData.id)}
					/>
				</div>
				<div className={cx(rs.space5, s.responsiveNoPadding)}>
					<Container fluid>
						<h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
							<FormattedMessage {...messages.itemSettings} />
						</h1>
						<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
							<div className={cx(rs.storeNewBg, rs.space5)}>
								<Row>
									<Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop1}>
										<div>
											<Row>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<Field
														name="itemName"
														type="text"
														placeholder={formatMessage(messages.itemName)}
														component={this.renderField}
														labelClass={s.labelText}
														fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
														label={formatMessage(messages.itemName)}
													/>
													<Field
														name="itemDescription"
														type="text"
														placeholder={formatMessage(messages.itemDescription)}
														component={this.renderTextAreaField}
														labelClass={s.labelText}
														fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
														label={formatMessage(messages.itemDescription)}
													/>
												</Col>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<Form.Group className={cx(s.space2, 'positionRelative')}>
														<label>{formatMessage(messages.itemImage)}</label>
														<div className={cx(rs.profileImgSection, rs.profileImgWidth, s.droupZoneGrid, 'storeLoader', 'storeLoaderContainer')}>
															<Loader
																show={imageLoader}
																type={"page"}
															>
																{
																	image ?
																		<div className={cx(rs.backgroundImg, s.backgroundTop)} style={{ backgroundImage: `url(${api.apiEndpoint + itemImageUploadDir + image})` }} />
																		:
																		<div className={cx(rs.backgroundImg, s.backgroundTop)}>
																			<img src={DefaultImage} className={rs.defaultImage} />
																		</div>
																}
															</Loader>
															<div className={'retaurantDroupZone'}>
																<ImageUploadComponent
																	className={cx(s.btnSecondary, s.profileNoPadding)}
																	subTextClass={s.subText}
																	subText={formatMessage(messages.maximumUploadSizeLabel)}
																	defaultMessage={formatMessage(messages.chooseFile)}
																	loaderName={'itemImageLoader'}
																	postUrl={api.apiEndpoint + '/uploadItemImage'}
																	loader={imageLoader}
																	fieldName={'image'}
																	formName={'AddItemForm'}
																	isLoaderExist={true}
																	label={formatMessage(messages.itemImage)}
																// imageSrc={`${api.apiEndpoint + itemImageUploadDir + image}`}
																/>
															</div>
														</div>
													</Form.Group>
												</Col>
												<Col lg={6} md={12} sm={12} xs={12}>
													<Field
														name="price"
														type="text"
														placeholder={formatMessage(messages.price)}
														component={this.renderPrice}
														labelClass={s.labelText}
														fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
														prefix={true}
														label={formatMessage(messages.price)}
													/>
												</Col>
												<Col lg={6} md={12} sm={12} xs={12}>
													<Field
														name="tax"
														type="text"
														placeholder={formatMessage(messages.tax)}
														component={this.renderPrice}
														labelClass={s.labelText}
														fieldClass={cx(rs.formControlInputStore, s.taxBorder, 'formControlInputStoreRTL')}
														prefixLabel={formatMessage(messages.percent)}
														prefix={false}
														label={formatMessage(messages.tax)}
													/>
												</Col>
												<Col lg={6} md={12} sm={12} xs={12}>
													<Field name="itemType"
														component={this.renderSelectField}
														fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
														label={formatMessage(messages.itemType)}
													>
														<option value={0}>{formatMessage(messages.selectType)}</option>
														<option value={1}>{formatMessage(messages.itemType1)}</option>
														<option value={2}>{formatMessage(messages.itemType2)}</option>
													</Field>
												</Col>
												<Col lg={6} md={12} sm={12} xs={12}>
													<Field name="isEnable" component={this.renderSelectField} label={formatMessage(messages.status)} fieldClass={rs.formControlInputStore}>
														<option value={true}>{formatMessage(messages.active)}</option>
														<option value={false}>{formatMessage(messages.inactive)}</option>
													</Field>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
								{isEnableAddon == '1' && < Row>
									<div className={s.borderCss}>
										<Col lg={12} md={12} sm={12} xs={12}>
											<hr className={s.hrLine} />
										</Col>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Row className={s.alignCenter}>
												<Col lg={6} md={12} sm={12} xs={12} className={'positionRelative'}>
													<label className={cx(s.modifierLabel, 'accordionLabel', 'modifierLabelRTL')}>{formatMessage(messages.addModifierGroup)}</label>
													<ModifierCommonType
													/>
												</Col>
												<Col lg={6} md={12} sm={12} xs={12} className={s.motifierBtn}>
													<Button className={cx(rs.button, rs.btnPrimary, s.displayInlineBlock)} onClick={() => openItemModal(menuId, subMenuId)}>
														<img src={PlusIcon} className={cx(rs.storeCommonPlusIcon, 'iconWidthRTL')} />
														<FormattedMessage {...messages.newModifierGroupsModal} />
													</Button>
												</Col>
											</Row>
											{
												modifierList && modifierList.length > 0 && modifierList.map((data, index1) => {
													return (

														data && data.modifierGroups.length > 0 && data.modifierGroups.map((item, index2) => {
															return (
																<div className='modifierAcccordion'>
																	<Accordion defaultActiveKey="0">
																		<Card>
																			<Card.Header>
																				<div className={s.menuItemGrid}>
																					<div className={s.menuFirstSection}>
																						<div className={cx(rs.alignLeft, rs.tabSection, s.paddingLeft)}>
																							<p className={cx(rs.idTitle, rs.textOverFlow, s.idTitleText)}>
																								{item.modifierName}

																							</p>
																							{
																								item.modifierCommonId && <Badge className={s.badge}>{formatMessage(messages.common)}</Badge>
																							}
																							{
																								!item.modifierCommonId && <Badge className={s.badge}>{formatMessage(messages.individual)}</Badge>
																							}
																						</div>
																						<div className={cx('storeActiveSelect', s.inactiveColor)}>
																							{!item.modifierCommonId && <select value={item.isActive} onChange={(e) => { this.handleStatus(index1, index2, e.target.value) }} className={cx(rs.activeSelect, rs.storeSelectInput)}>
																								<option value={"true"}>{formatMessage(messages.active)}</option>
																								<option value={"false"}>{formatMessage(messages.inactive)}</option>
																							</select>}
																							{item.modifierCommonId && item.isActive === "false" && formatMessage(messages.inactive)}
																						</div>
																						<div className={rs.editCenterBtn}>
																							<Button className={cx(rs.linkColor, s.displayInlineBlock)} onClick={() => editItemModal('ModifierGroupForm', index1, index2)}>
																								<img src={EditIcon} className={s.iconsWidth} />
																								<span className={cx(s.vtrMiddle, s.editSection)}>{formatMessage(messages.editItem)}</span>
																							</Button>
																						</div>
																						<div className={rs.editCenterBtn}>
																							<Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => this.handleModifierItem(index1, item.id)}>
																								<img src={TrashIcon} className={s.iconsWidth} />
																								<span className={s.vtrMiddle}>{formatMessage(messages.deleteAction)}</span>
																							</Button>

																						</div>
																					</div>
																				</div>
																				<Accordion.Toggle variant="link" eventKey="1" className={'arrowBtn'} onClick={() => this.toggleCollapse(item.id)}>
																					<img src={collapseID.includes(item.id) ? upArrow : downArrow} width={'30px'} height={'30px'} />
																				</Accordion.Toggle>
																			</Card.Header>
																			<Accordion.Collapse eventKey="1">
																				<Card.Body>
																					{
																						item.modifierItems && item.modifierItems.length > 0 && <span>{formatMessage(messages.item)}: </span>
																					}
																					{
																						item.modifierItems && item.modifierItems.length > 0 && item.modifierItems.map(function (elem) {
																							return elem.modifierItemName;
																						}).join(", ")
																					}
																				</Card.Body>
																			</Accordion.Collapse>
																		</Card>
																	</Accordion>
																</div>
															)
														})
													)
												})
											}
										</Col>
									</div>
								</Row>}
							</div>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(rs.alignRightText)}>
									<Form.Group className={cx(s.padding2, s.rightSide)}>
										<div className={rs.secondryBtnSection}>
											<a
												href={'javascript:void(0)'}
												onClick={this.handleCancel}
												className={cx(rs.button, rs.btnPrimaryBorder, rs.displayBlock)}
											>
												<FormattedMessage {...messages.cancel} />
											</a>
										</div>
										<div className={cx(rs.secondryBtnSection, 'rtlLoader')}>
											<Loader
												type={"button"}
												label={id ? formatMessage(messages.update) : formatMessage(messages.save)}
												show={loading}
												buttonType={'submit'}
												className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Container>
				</div>
			</div >
		)
	}

}

AddItemForm = reduxForm({
	form: 'AddItemForm',
	onSubmit: submit,
	validate
})(AddItemForm);

const selector = formValueSelector('AddItemForm');

const mapState = (state) => ({
	loading: state?.loader?.CommonSettings,
	image: selector(state, 'image'),
	id: selector(state, 'id'),
	price: selector(state, 'price'),
	menuId: selector(state, 'menuId'),
	subMenuId: selector(state, 'subMenuId'),
	modifierGroupId: selector(state, 'modifierGroupId'),
	modifiers: selector(state, 'modifiers'),
	profile: state?.account?.data?.profile,
	currentLocale: state?.intl?.locale,
	base: state?.currency?.base,
	rates: state?.currency?.rates,
	menuDeleteModal: state?.modalStatus?.menuDeleteModal,
	modalData: state?.modalStatus?.data && state?.modalStatus?.data[0],
	isEnableAddon: state?.siteSettings?.data?.modifiers,
	imageLoader: state?.loader?.itemImageLoader,
});

const mapDispatch = {
	change,
	deleteMenu,
	openModal,
	closeModal,
	openItemModal,
	getExistingModifier,
	editItemModal,
	getBookedModifierGroup
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(AddItemForm)));