import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Tabs, Tab, FormControl, Badge, Container } from 'react-bootstrap';
import cx from 'classnames';

import s from './OrderManagement.css';
import rs from '../../storeCommon.css';
import messages from '../../../locale/messages';

import getAllOrders from './getAllOrders.graphql';
import history from '../../../history';
import debounce from '../../../helpers/debounce';

import CustomPagination from '../../CustomPagination/CustomPagination';
import Loader from '../../Common/Loader';
import Orders from './Orders';

class OrderManagement extends Component {
    static defaultProps = {
        orders: {
            loading: true
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            key: 'upcoming',
            currentPage: 1,
            searchList: ''
        };

        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.paginationData = this.paginationData.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
    }

    componentDidMount() {
        let pathName = history.location.pathname.split('/');
        this.setState({ key: pathName && pathName.length > 2 && pathName[3] });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let pathName = history.location.pathname.split('/');
        this.setState({
            key: pathName[3]
        });
    }

    handleTabSelect = (key) => {
        this.setState({ currentPage: 1 });
        history.push('/store/order-management/' + key);
    }

    handleKeywordSearch(searchList) { // Keyword search
        const { type, orders: { refetch }, changeKeyword } = this.props;

        refetch({ type, currentPage: 1, searchList });
        changeKeyword(searchList); // Storing keywords to the parent component
        this.setState({ searchList, currentPage: 1 });
    }

    paginationData(currentPage) { // Pagination
        const { type, orders, orders: { loading, refetch } } = this.props;
        const { searchList } = this.state;
        if (orders && !loading) {
            refetch({ type, currentPage, searchList });
            this.setState({ searchList, currentPage });
        }
    }

    renderPagination(paginationLabel, currentPage, count) {
        return (
            <div className={cx(s.space5, s.spaceTop5, 'store')}>
                <CustomPagination
                    total={count}
                    currentPage={currentPage}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    change={this.paginationData}
                    paginationLabel={paginationLabel}
                />
            </div>
        );
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { orders, orders: { loading, refetch }, type, socket } = this.props;
        const { key, currentPage } = this.state;
        let pending = orders && orders.getAllOrders && orders.getAllOrders.pendingCount;

        return (
            <div className={'mainContainer'}>
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h1 className={cx(s.titleBottom, rs.storeTitleText, 'textAlignRightRTL')}><FormattedMessage {...messages.orderManageMent} /></h1>
                            <FormControl type='text' placeholder={formatMessage(messages.searchById)}
                                className={cx(rs.formControlInputStore, rs.searchInputWidth, s.searchInput, 'formControlInputStoreRTL')}
                                onKeyUp={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                            />
                        </Col>
                        <Col md={12} lg={12} sm={12} xs={12} className={rs.spaceTop5}>
                            <div>
                                <div>
                                    <Tabs activeKey={key} id="order-management" className="storeTabBtnGroup" onSelect={(eventKey) => this.handleTabSelect(eventKey)}>
                                        <Tab eventKey="upcoming" title={
                                            <React.Fragment>
                                                {formatMessage(messages.currentOrders)}
                                                {
                                                    pending > 0 && <Badge className={s.badge}>{pending}</Badge>
                                                }
                                            </React.Fragment>
                                        }>
                                            {
                                                loading && <Loader containerClass={'text-center'} />
                                            }
                                            {
                                                !loading && orders && orders.getAllOrders && key === 'upcoming' && <Orders
                                                    data={orders}
                                                    currentPage={currentPage}
                                                    paginationData={this.paginationData}
                                                    type={type}
                                                    socket={socket}
                                                    showDeclineModal={true}
                                                />
                                            }
                                            {
                                                !loading && orders && orders.getAllOrders && orders.getAllOrders.results && orders.getAllOrders.results.length > 0 && key === 'upcoming' &&
                                                this.renderPagination(formatMessage(messages.ordersLabel), currentPage, orders.getAllOrders.count)
                                            }
                                        </Tab>
                                        <Tab eventKey="previous" title={formatMessage(messages.previousOrders)}>
                                            {
                                                loading && <Loader containerClass={'text-center'} />
                                            }
                                            {
                                                !loading && orders && orders.getAllOrders && key !== 'upcoming' && <Orders
                                                    data={orders}
                                                    currentPage={currentPage}
                                                    paginationData={this.paginationData}
                                                    type={type}
                                                    socket={socket}
                                                />
                                            }
                                            {
                                                !loading && orders && orders.getAllOrders && orders.getAllOrders.results && orders.getAllOrders.results.length > 0 && key !== 'upcoming' &&
                                                this.renderPagination(formatMessage(messages.ordersLabel), currentPage, orders.getAllOrders.count)
                                            }
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

const mapDispatch = {};

const mapState = (state) => ({});

export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(getAllOrders, {
        name: 'orders',
        options: (props) => ({
            variables: {
                type: props.type,
                currentPage: 1,
                searchList: props.searchList
            },
            fetchPolicy: 'network-only'
        })
    })
)(OrderManagement);