import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import normalizeCss from 'normalize.css';
import Footer from '../../../Footer/Footer';
import Toaster from '../../../Toaster/Toaster';
import { connect } from 'react-redux';
import StoreHeader from '../StoreHeader/StoreHeader';
import StoreSideMenu from '../../StoreSideMenu/StoreSideMenu';
import rs from '../../../storeCommon.css';
import history from '../../../../history';
import SocketContext from '../../../SocketContext';
import s from './Layout.css';


class DashboardLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };
    render() {
        let location;
        if (history.location) {
            location = history.location.pathname
        }

        return (
            <SocketContext.Consumer>
                {
                    socket => (
                        <div className={'store'}>
                            <Toaster />
                            <div className={rs.storesideNav}>
                                <StoreSideMenu location={location} />
                            </div>
                            <div className={cx(rs.storeMainContent, 'print-pagecontentWrapper', 'storeMainContentRTL')}>
                                <div className={rs.storeHeaderBg}>
                                    <StoreHeader socket={socket} />
                                </div>
                                <div className={cx(s.storePaddingTop, "printPaddingTop")}>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    )
                }
            </SocketContext.Consumer>
        );
    }
};


const mapStateToProps = (state) => ({
    isStore: state.user.store
});

const mapDispatchToProps = {

};

export default withStyles(normalizeCss, s, rs)(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));